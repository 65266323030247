import React, { useState, ChangeEvent } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import ValidationService from "../../services/ValidationService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Error } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";

interface EditTask {
  id: string;
  name: string;
  description: string;
}

function EditTask(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const [editTask, setEditTask] = useState<EditTask>({
    id: props.itemEdit.id,
    name: props.itemEdit.name,
    description: props.itemEdit.description,
  });
  const [name, setName] = useState(props.itemEdit.name);
  const [description, setDescription] = useState(props.itemEdit.description);

  const hideItemEdit = () => {
    props.getItems();
    props.setSelectedItem(null);
  };

  const submitEditTask = () => {
    try {
      ApiService.editTask(editTask).then((response: any) => {
        if (response.data.length === 0) {
          props.getItems();
          hideItemEdit();
        } else {
          setErrors(response.data);
          setIsValid(false);
        }
      });
    } catch (e: any) {
      setIsValid(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setEditTask((prevProfile) => ({ ...prevProfile, [id]: value }));
  };

  return (
    <>
      {!isValid &&
        errors.map((error: Error, index) => (
          <Alert key={index} variant="warning">
            {error.message}
          </Alert>
        ))}
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name:
        </label>
        <input
          id="name"
          type="text"
          value={editTask.name}
          className="form-control"
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Description:
        </label>
        <textarea
          id="description"
          className="form-control"
          value={editTask.description}
          onChange={handleInputChange}
        />
      </div>
      <div className="row mt-4">
        <div className="col-md-6">
          <Button variant="primary-outline" onClick={hideItemEdit}>
            Go Back
          </Button>
        </div>
        <div className="col-md-6 text-right">
          <Button variant="primary" onClick={() => submitEditTask()}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
}

export { EditTask };
