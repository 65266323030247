import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Sliders,
  ListColumnsReverse,
  ArrowUp,
  ArrowDown,
  PencilSquare,
  CheckSquare,
  ChatSquareText,
  ArrowClockwise,
} from "react-bootstrap-icons";
import { Files, CloudDownload, Plus } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { useAuthUser } from "react-auth-kit";
import { set, useForm } from "react-hook-form";
import { SlidesDisplay } from "../common/slides-display";
import { Error } from "../../context/UserContext";
import { TaskHistory } from "./tasks-history";
import moment from "moment";
import { FilterTasks } from "./tasks-filter";
import Spinner from "react-bootstrap/Spinner";
import { useGlobalContext } from "../../GlobalContext";
import PPTXIcon from "../../assets/images/pptx.jpg";

type FormData = {
  id: string;
  description: string;
  file: FileList;
};

type WorkflowSubmitData = {
  id: string;
  action: string;
  note: string;
};

function ListTasks(props: any) {
  const [showImages, setShowImages] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showAddTaskHistory, setShowAddTaskHistory] = useState(false);
  const [showCancelTask, setShowCancelTask] = useState(false);
  const [showTaskHistory, setShowTaskHistory] = useState(false);
  const [task, setTask] = useState();
  const [taskId, setTaskId] = useState("");
  const [images, setImages] = useState([]);
  const [imagesOriginal, setImagesOriginal] = useState([]);
  const auth = useAuthUser();
  const { register, handleSubmit } = useForm<FormData>();
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const [filteredStatus, setFilteredStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const context = useGlobalContext();
  const [sortColumn, setSortColumn] = useState<string | null>("createdString");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [isPhone, setIsPhone] = useState(window.innerWidth < 768);

  const handleCloseImages = () => setShowImages(false);
  const handleCloseUpload = () => setShowUpload(false);
  const handleCloseTaskHistory = () => setShowTaskHistory(false);
  const handleCloseAddTaskHistory = () => setShowAddTaskHistory(false);
  const handleCloseCancelTask = () => setShowCancelTask(false);

  const [workflowSubmitData, setWorkflowSubmitData] =
    useState<WorkflowSubmitData>({
      id: "",
      action: "",
      note: "",
    });

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleShowImages = (item: any) => {
    setTask(item.name);
    setShowImages(true);
    ApiService.getTask(item.id).then((response: any) => {
      console.log("Task -->");
      console.log(response.data);
      setImages(response.data.slidesUpdated);
      setImagesOriginal(response.data.slidesOriginal);
    });
  };

  const handleShowUpload = (item: any) => {
    setTask(item.name);
    setTaskId(item.id);
    setShowUpload(true);
  };

  const handleShowCancelTask = (item: any) => {
    setTask(item.name);
    setTaskId(item.id);
    setShowCancelTask(true);
  };

  const downloadTask = (id: string) => {
    try {
      setIsLoading(true);
      var apiUrl = context.apiUrl + "api/";
      ApiService.getDownloadTask(id).then((response: any) => {
        setIsLoading(false);
        window.open(apiUrl + "task/download/direct/" + response.data, "_blank");
      });
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
      setIsLoading(false);
    }
  };

  const workflowSubmit = (id: string, action: string, note: string) => {
    let tmpWorkflowSubmitData = workflowSubmitData;
    tmpWorkflowSubmitData.id = id;
    tmpWorkflowSubmitData.action = action;
    tmpWorkflowSubmitData.note = note;
    setWorkflowSubmitData(tmpWorkflowSubmitData);
    setIsLoading(true);
    ApiService.workflowSubmit(workflowSubmitData).then((response: any) => {
      props.itemsRefresh();
      setIsLoading(false);
    });
  };

  const onSubmit = async (data: FormData) => {
    if (data.file.length === 0) {
      setIsValid(false);
      setErrors([
        {
          type: "password",
          message: "A file is required",
          stackTrace: "",
        },
      ]);
    } else {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("id", taskId);
        formData.append("description", data.description);
        formData.append("file", data.file[0]);
        const response = await ApiService.addTaskFile(formData);
        setErrors(response.data);
        if (response.data.length > 0) {
          setIsValid(false);
        } else {
          console.log("File Uploaded!");
          props.itemsRefresh();
          handleCloseUpload();
        }
        setIsLoading(false);
      } catch (e: any) {
        setIsValid(false);
        console.log("Error -> " + e.toJSON().message);
        setIsLoading(false);
      }
    }
  };

  const cancelTask = async () => {
    try {
      setIsLoading(true);
      const response = await ApiService.cancelTask(taskId);
      console.log(response.data);
      props.itemsRefresh();
      handleCloseCancelTask();
      setIsLoading(false);
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
      setIsLoading(false);
    }
  };

  const handleShowTaskHistory = (item: any) => {
    setTask(item.name);
    setTaskId(item.id);
    setShowTaskHistory(true);
  };

  const handleFilterChange = (status: string) => {
    setFilteredStatus(status);
  };

  const handleShowTaskEdit = (item: any) => {
    props.setItemEdit(item);
    props.setSelectedEdit(true);
  };

  const toggleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortItems = (items: any[]) => {
    if (!sortColumn) return items;

    return [...items].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const renderSortArrow = (column: string) => {
    if (sortColumn !== column) {
      return null;
    }

    if (sortDirection === "desc") {
      return <ArrowDown />;
    } else {
      return <ArrowUp />;
    }
  };

  const getUserRoleIcon = (role: string) => {
    if (role === "Editor") {
      return <PencilSquare title="Editor" />;
    } else if (role === "Approver") {
      return <CheckSquare title="Approver" />;
    } else if (role === "Reviewer") {
      return <ChatSquareText title="Reviewer" />;
    } else {
      return null;
    }
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-background">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="row mb-3">
        <div className="col-md-3 col-sm-6 text-left">
          <FilterTasks onFilterChange={handleFilterChange} />
        </div>
        <div className="col-md-9 col-sm-6 text-right">
          <Button
            variant="outline-warning mt-4"
            onClick={() => props.getItems()}
            title="Refresh"
          >
            <ArrowClockwise />
          </Button>
          &nbsp;
          <Button
            variant="primary-outline mt-4"
            onClick={() => props.setSelectedAdd(true)}
          >
            Add {auth()?.transTask}
          </Button>
        </div>
      </div>

      <table className="table fs-0 mb-0 border-top border-200">
        <thead>
          <tr>
            <th className="mouse-pointer" onClick={() => toggleSort("name")}>
              Name&nbsp;{renderSortArrow("name")}
            </th>
            <th
              className="mouse-pointer"
              onClick={() => toggleSort("createdString")}
            >
              Created&nbsp;{renderSortArrow("createdString")}
            </th>
            <th
              className="mouse-pointer text-center"
              onClick={() => toggleSort("typeString")}
            >
              Type&nbsp;{renderSortArrow("typeString")}
            </th>

            <th>{auth()?.transCollab}</th>
            <th className="text-center">Slides</th>
            <th
              className="mouse-pointer"
              onClick={() => toggleSort("workflowStatusName")}
            >
              Status&nbsp;{renderSortArrow("workflowStatusName")}
            </th>
            <th
              className="mouse-pointer"
              onClick={() => toggleSort("lastActivity")}
            >
              Activity&nbsp;{renderSortArrow("lastActivity")}
            </th>
            <th>Users</th>
            <th className="text-center">Download</th>
            <th className="text-center">Upload</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortItems(props.items)
            .filter(
              (item: any) =>
                filteredStatus === "" ||
                item.workflowStatusName === filteredStatus
            )
            .map((item: any) => (
              <tr
                key={item.id}
                className={
                  item.workflowStatusName === "Completed" ? "task-light" : ""
                }
              >
                <td>{item.name}</td>
                <td>
                  <span
                    className="badge rounded-pill bg-secondary"
                    title={item.createdString}
                  >
                    {item.createdStringSimple}
                  </span>
                </td>
                <td className="text-center">
                  <img
                    src={PPTXIcon}
                    alt="PowerPoint"
                    title="PowerPoint"
                    width="25"
                  ></img>
                </td>
                <td>
                  <span title={item.collaboration.name}>
                    {item.collaboration.name.length > 25
                      ? `${item.collaboration.name.substring(0, 25)}...`
                      : item.collaboration.name}
                  </span>
                </td>
                <td className="text-center">
                  <span
                    onClick={() => handleShowImages(item)}
                    className="mouse-pointer"
                  >
                    <Files />
                  </span>
                </td>
                <td>
                  {item.workflowStatusName === "In Progress" && (
                    <span className="badge text-bg-primary">
                      {item.workflowStatusName}
                    </span>
                  )}
                  {item.workflowStatusName === "Pending Approval" && (
                    <span className="badge text-bg-warning">
                      {item.workflowStatusName}
                    </span>
                  )}
                  {item.workflowStatusName === "Completed" && (
                    <span className="badge text-bg-light">
                      {item.workflowStatusName}
                    </span>
                  )}
                </td>
                <td>
                  <span
                    onClick={() => handleShowTaskHistory(item)}
                    className="badge rounded-pill bg-secondary mouse-pointer"
                    title={moment(item.lastActivity).format(
                      "YYYY-MMM-DD HH:mm"
                    )}
                  >
                    {item.lastActivityString}
                  </span>{" "}
                  &nbsp;
                  <span
                    onClick={() => handleShowTaskHistory(item)}
                    className="mouse-pointer"
                  >
                    <ListColumnsReverse />
                  </span>
                </td>
                <td>
                  {item.users.map((user: any) => (
                    <span
                      key={user.id}
                      className="taskUser badge bg-primary me-1"
                      title={user.fullName + " (" + user.role + ")"}
                    >
                      {user.initials} {getUserRoleIcon(user.role)}
                    </span>
                  ))}
                </td>
                <td className="text-center">
                  <span
                    onClick={() => downloadTask(item.id)}
                    className="mouse-pointer"
                  >
                    <CloudDownload />
                  </span>
                </td>
                <td className="text-center">
                  {item.workflowStatusName !== "Completed" && (
                    <span
                      onClick={() => handleShowUpload(item)}
                      className="mouse-pointer"
                    >
                      <Files />
                    </span>
                  )}
                  {item.workflowStatusName === "Completed" && (
                    <span>
                      <Files />
                    </span>
                  )}
                </td>
                <td className="text-end">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      size="sm"
                    >
                      <Sliders size={10} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {item.actions.map((action: any) => (
                        <Dropdown.Item
                          key={action.id}
                          onClick={() =>
                            workflowSubmit(item.id, action.name, "")
                          }
                        >
                          {action.name}
                        </Dropdown.Item>
                      ))}
                      {item.actions.length > 0 && <Dropdown.Divider />}
                      {item.canEdit &&
                        item.workflowStatusName !== "Completed" && (
                          <Dropdown.Item
                            onClick={() => handleShowTaskEdit(item)}
                            disabled={!item.canEdit}
                          >
                            Edit
                          </Dropdown.Item>
                        )}
                      <Dropdown.Item onClick={() => downloadTask(item.id)}>
                        Download
                      </Dropdown.Item>
                      {item.canDelete && (
                        <Dropdown.Item disabled={!item.canDelete}>
                          Delete
                        </Dropdown.Item>
                      )}
                      {item.canCancel && (
                        <Dropdown.Item
                          disabled={!item.canCancel}
                          onClick={() => handleShowCancelTask(item)}
                        >
                          Cancel
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal
        show={showImages}
        onHide={handleCloseImages}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            {auth()?.transTask} Thumbnails - {task}
          </Modal.Title>
          <Button variant="close" onClick={handleCloseImages}></Button>
        </Modal.Header>
        <Modal.Body>
          <SlidesDisplay
            originalSlides={imagesOriginal}
            updatedSlides={images}
            initialSize="col-md-12"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleCloseImages}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showUpload}
        onHide={handleCloseUpload}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title>
              {auth()?.transTask} Upload - {task}
            </Modal.Title>
            <Button variant="close" onClick={handleCloseUpload}></Button>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Note:
              </label>
              <textarea
                id="description"
                {...register("description")}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="file" className="form-label">
                File:
              </label>
              <input
                id="file"
                type="file"
                {...register("file")}
                className="form-control"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary-outline" onClick={handleCloseUpload}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Upload File
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={showTaskHistory}
        onHide={handleCloseTaskHistory}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            {auth()?.transTask} History - {task}
          </Modal.Title>
          <Button variant="close" onClick={handleCloseTaskHistory}></Button>
        </Modal.Header>
        <Modal.Body>
          <TaskHistory id={taskId} closeModal={handleCloseTaskHistory} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleCloseTaskHistory}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAddTaskHistory}
        onHide={handleCloseAddTaskHistory}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            Add {auth()?.transTask} Activity Note - {task}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <textarea id="addActivity" className="form-control" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleCloseAddTaskHistory}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Add Activity Note
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCancelTask}
        onHide={handleCloseCancelTask}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            Cancel {auth()?.transTask} - {task}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this {auth()?.transTask}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleCloseCancelTask}>
            Close
          </Button>
          <Button variant="primary" onClick={() => cancelTask()}>
            Cancel Task
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { ListTasks };
