import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ApiService from "../../services/ApiService";
import Dropdown from "react-bootstrap/Dropdown";
import "../../css/app.css";
import { Sliders, ArrowUp, ArrowDown } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import { UserContext } from "../../context/UserContext";
import Spinner from "react-bootstrap/Spinner";

function UsersList(props: any) {
  const [showDelete, setDeleteShow] = useState(false);
  const [showActivity, setActivityShow] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [deleteUserName, setDeleteUserName] = useState("");
  const [activityUserId, setActivityUserId] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const { user, setUser } = useContext(UserContext);
  const [activityLogs, setActivityLogs] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersSortBy, setUsersSortBy] = useState("firstName");
  const [usersSortOrder, setUsersSortOrder] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState<string | null>("firstName");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = (user: any) => {
    setDeleteUserId(user.id);
    setDeleteUserName(user.email);
    setDeleteShow(true);
  };

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  const handleActivityClose = () => setActivityShow(false);
  const handleActivityShow = (user: any) => {
    try {
      setIsLoading(true);
      setActivityUserId(user.id);
      setActivityShow(true);
      const values = {
        userId: user.id,
      };
      ApiService.getUserActivity(values).then((response: any) => {
        setActivityLogs(response.data);
        setIsLoading(false);
      });
      console.log("User Activity --> " + user.id);
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
      setIsLoading(false);
    }
  };

  const deleteUser = async () => {
    const values = {
      userid: deleteUserId,
    };
    try {
      const response = await ApiService.deleteUser(values);
      setErrors(response.data);
      if (response.data.length > 0) {
        setDeleteShow(false);
        setIsValid(false);
      } else {
        setIsValid(true);
        setDeleteShow(false);
        setDeleteUserId("");
        setDeleteUserName("");
        props.usersRefresh(true);
      }
      console.log(response.data);
    } catch (e: any) {
      setIsValid(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const toggleUserActive = async (userId: any) => {
    const values = {
      userid: userId,
    };
    try {
      const response = await ApiService.toggleUserActive(values);
      setErrors(response.data);
      if (response.data.length > 0) {
        setIsValid(false);
      } else {
        setIsValid(true);
        props.usersRefresh(true);
      }
      console.log(response.data);
    } catch (e: any) {
      setIsValid(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const toggleUserTwoFactor = async (userId: any) => {
    const values = {
      userid: userId,
    };
    try {
      const response = await ApiService.toggleUserTwoFactor(values);
      setErrors(response.data);
      if (response.data.length > 0) {
        setIsValid(false);
      } else {
        setIsValid(true);
        props.usersRefresh(true);
      }
      console.log(response.data);
    } catch (e: any) {
      setIsValid(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const openEditUser = (user: any) => {
    props.onEditUser(user);
  };

  const openUserAdd = () => {
    props.setSelectedUserAdd(true);
  };

  const openUserInvite = () => {
    props.setSelectedUserInvite(true);
  };

  const toggleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortItems = (items: any[]) => {
    if (!sortColumn) return items;

    return [...items].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const renderSortArrow = (column: string) => {
    if (sortColumn !== column) {
      return null;
    }

    if (sortDirection === "desc") {
      return <ArrowDown />;
    } else {
      return <ArrowUp />;
    }
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-background">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!isValid &&
        errors.map((error: Error, index) => (
          <Alert key={index} variant="warning">
            {error.message}
          </Alert>
        ))}
      <div className="row mb-3">
        <div className="col-md-12 text-right">
          <Button variant="primary" onClick={openUserAdd}>
            Add User
          </Button>
          &nbsp;
          <Button variant="primary" onClick={openUserInvite}>
            Invite User
          </Button>
        </div>
      </div>
      <table className="table fs-0 mb-0 border-top border-200">
        <thead>
          <tr>
            <th
              className="mouse-pointer"
              onClick={() => toggleSort("firstName")}
            >
              First Name&nbsp;{renderSortArrow("firstName")}
            </th>
            <th
              className="mouse-pointer"
              onClick={() => toggleSort("lastName")}
            >
              Last Name&nbsp;{renderSortArrow("lastName")}
            </th>
            <th className="mouse-pointer" onClick={() => toggleSort("email")}>
              Username&nbsp;{renderSortArrow("email")}
            </th>
            <th>Role(s)</th>
            <th className="mouse-pointer" onClick={() => toggleSort("active")}>
              Active&nbsp;{renderSortArrow("active")}
            </th>
            <th className="mouse-pointer" onClick={() => toggleSort("status")}>
              Status&nbsp;{renderSortArrow("status")}
            </th>
            <th
              className="mouse-pointer"
              onClick={() => toggleSort("isTwoFactor")}
            >
              Two Factor&nbsp;{renderSortArrow("isTwoFactor")}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortItems(users).map(
            (item: any) => (
              <tr key={item.id}>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.email}</td>
                <td>
                  {item.roles.map(
                    (role: any) => (
                      <span key={role.id}>{role.name}</span>
                    ),
                    []
                  )}
                </td>
                <td>
                  <Form.Check
                    type="switch"
                    id="userActiveSwitch"
                    disabled={item.id === user?.id}
                    defaultChecked={item.active}
                    onChange={() => toggleUserActive(item.id)}
                    className="primary-outline"
                  />
                </td>
                <td>{item.status}</td>
                <td>
                  <Form.Check
                    type="switch"
                    id="userTwoFactorSwitch"
                    defaultChecked={item.isTwoFactor}
                    onChange={() => toggleUserTwoFactor(item.id)}
                  />
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      size="sm"
                    >
                      <Sliders size={10} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleActivityShow(item)}>
                        Activity
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => openEditUser(item)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={user?.id === item.id}
                        onClick={() => handleDeleteShow(item)}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ),
            []
          )}
        </tbody>
      </table>
      <Modal
        show={showDelete}
        onHide={handleDeleteClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {deleteUserName}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleDeleteClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showActivity}
        onHide={handleActivityClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>User Activity (UTC)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inner-activity">
            {activityLogs.map(
              (item: any) => (
                <div key={item.id} className="row">
                  <div className="col-md-5">
                    <em>
                      {item.createdDateString} {item.createdTimeString}
                    </em>
                  </div>
                  <div className="col-md-7">
                    {item.action}
                    {item.description !== "" ? <br /> : ""}
                    {item.description !== "" ? <em>{item.description}</em> : ""}
                  </div>
                </div>
              ),
              []
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleActivityClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { UsersList };
