/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Square, Check2Square } from "react-bootstrap-icons";
import { SlidesDisplaySimple } from "../common/slides-display-simple";
import { Files } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import ApiService from "../../services/ApiService";

function TopicSuggestions(props: any) {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue } = useForm<FormData>();
  const [query, setQuery] = useState("");

  const handleCloseModal = () => setShowModal(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const selectSuggestion = (item: string) => {
    props.populateTopic(item);
    setShowModal(false);
  };

  useEffect(() => {
    // console.log(props.themeSelectedId);
  }, []);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("query", query);
      const response = await ApiService.getSuggestions(formData);
      setIsLoading(false);
      if (response.data.length > 0) {
        setItems(response.data);
      }
    } catch (e: any) {
      setIsLoading(false);
      console.log("Error -> " + e);
    }
  };

  return (
    <>
      {isLoading && (
        <>
          <div className="spinner-background">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      )}
      <span className="text-s5 mb-2">
        <a href="#" onClick={handleShowModal}>
          Help me Create a Presentation Topic (based on keywords)
        </a>
      </span>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        className="modal"
        dialogClassName="modal-50w"
      >
        <Modal.Header>
          <Modal.Title>Topic Suggestions</Modal.Title>
          <Button variant="close" onClick={handleCloseModal}></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Enter keyworks to generate a presentation topic:
              </label>
              <input
                id="name"
                type="text"
                onChange={(e) => setQuery(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="row mt-4">
              <div className="col-md-6">&nbsp;</div>
              <div className="col-md-6 text-right">
                <Button
                  variant="primary"
                  onClick={() => onSubmit()}
                  disabled={isLoading}
                >
                  Get Suggestions
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            {items.length > 0 && (
              <>
                <hr />
                <div>
                  <div className="alert alert-info" role="alert">
                    You can click on a suggestion to populate the topic field.
                  </div>
                  <h4>Suggestions:</h4>
                  <ul>
                    {items.map((item: any, index: number) => (
                      <li
                        className="mouse-pointer"
                        key={index}
                        onClick={() => selectSuggestion(item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { TopicSuggestions };
