import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import { Error } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";
import { useForm } from "react-hook-form";
import { useAuthUser } from "react-auth-kit";
import Spinner from "react-bootstrap/Spinner";
import { Files } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { SlidesDisplaySimple } from "../common/slides-display-simple";

type FormData = {
  name: string;
  description: string;
  templateId: string;
};

function AddCollaborationTemplate(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const { register, handleSubmit, setValue } = useForm<FormData>();
  const [uploadStatus, setUploadStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuthUser();
  const [templates, setTemplates] = useState([]);
  const [slides, setSlides] = useState([] as any);
  const [images, setImages] = useState([]);
  const [showImages, setShowImages] = useState(false);
  const [template, setTemplate] = useState("");

  const hideItemAdd = () => {
    props.hideItemAdd();
  };

  const handleCloseImages = () => setShowImages(false);

  const handleShowImages = () => {
    setShowImages(true);
    setImages(slides);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = async () => {
    try {
      setIsLoading(true);
      const response = await ApiService.getTemplates();
      if (response.data.length === 0) {
        props.setShowTemplates(false);
      } else {
        props.setShowTemplates(true);
      }
      setTemplates(response.data);
      setValue("templateId", response.data[0].id);
      setValue("name", response.data[0].name);
      setTemplate(response.data[0].name);
      setSlides(response.data[0].slides);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const templateSelected = (e: string) => {
    templates.map((template: { id: string; name: string; slides: [] }) => {
      if (template.id === e) {
        setValue("name", template.name);
        setSlides(template.slides);
        setTemplate(template.name);
      }
    });
  };

  const onSubmit = async (data: FormData) => {
    if (!data.name) {
      setIsValid(false);
      setErrors([
        {
          type: "required",
          message: "A name is required",
          stackTrace: "",
        },
      ]);
    } else {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("templateId", data.templateId);
        const response = await ApiService.addCollaborationFromTemplate(
          formData
        );
        setErrors(response.data);
        setIsLoading(false);
        if (response.data.length > 0) {
          setIsValid(false);
        } else {
          console.log("File Uploaded!");
          hideItemAdd();
        }
      } catch (e: any) {
        setIsValid(false);
        setIsLoading(false);
        console.log(e);
      }
    }
  };

  return (
    <>
      {!isValid &&
        errors.map((error: Error, index) => (
          <Alert key={index} variant="warning">
            {error.message}
          </Alert>
        ))}
      {props.firstCollab && (
        <div className="alert alert-primary mt-3" role="alert">
          Enter a name, description (optional) and select the template you want.
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading && (
          <div className="spinner-background">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <div className="mb-3">
          <label htmlFor="templateId" className="form-label">
            {auth()?.transCollab} Template:
          </label>
          <select
            id="templateId"
            {...register("templateId")}
            onChange={(e) => templateSelected(e.target.value)}
            className="form-select"
          >
            {template != null &&
              templates
                .filter(
                  (template: { id: string; name: string; isTheme: boolean }) =>
                    !template.isTheme
                )
                .map(
                  (
                    template: { id: string; name: string; isTheme: boolean },
                    index
                  ) => (
                    <option key={index} value={template.id}>
                      {template.name}
                    </option>
                  )
                )}
          </select>{" "}
          &nbsp;
          <span className="mouse-pointer" onClick={() => handleShowImages()}>
            <Files /> View Slides
          </span>
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            {auth()?.transCollab} Name:
          </label>
          <input
            id="name"
            type="text"
            {...register("name")}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description:
          </label>
          <textarea
            id="description"
            {...register("description")}
            className="form-control"
          />
        </div>
        <div className="mb-3 mt-3">
          <div className="alert alert-info" role="alert">
            Please note that based on template file size, it may take a few
            minutes to upload and process. Please be patient.
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <Button
              variant="primary-outline"
              onClick={hideItemAdd}
              hidden={props.firstCollab}
            >
              Go Back
            </Button>
          </div>
          <div className="col-md-6 text-right">
            <Button variant="primary" type="submit" disabled={isLoading}>
              Start {auth()?.transCollab}
            </Button>
          </div>
        </div>
      </form>
      <Modal
        show={showImages}
        onHide={handleCloseImages}
        backdrop="static"
        keyboard={false}
        className="modal-xl"
      >
        <Modal.Header>
          <Modal.Title>Template - {template}</Modal.Title>
          <Button variant="close" onClick={handleCloseImages}></Button>
        </Modal.Header>
        <Modal.Body>
          <SlidesDisplaySimple slides={images} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleCloseImages}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { AddCollaborationTemplate };
