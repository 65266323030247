import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { TenantSettingsEdit } from "../../context/UserContext";
import { useAuthUser } from "react-auth-kit";

function TenantSettings() {
  const navigate = useNavigate();
  const [tenantSettings, setTenantSettings] = useState([]);
  const [tenantSettingsRefresh, setTenantSettingsRefresh] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const auth = useAuthUser();
  const [user, setUser] = useState();

  useEffect(() => {
    onRefresh();
    handleEditGet();
  }, []);

  useEffect(() => {
    handleEditGet();
  }, [tenantSettingsRefresh, setTenantSettingsRefresh]);

  const onRefresh = async () => {
    const response2fa = await ApiService.isTwoFactor();
    if (response2fa.data === true) {
      navigate("/twofactor");
    }
  };

  const handleEditGet = async () => {
    try {
      const response = await ApiService.getTenantSettings();
      formik.values.collaboration = response.data.collaboration;
      formik.values.collaborations = response.data.collaborations;
      formik.values.task = response.data.task;
      formik.values.tasks = response.data.tasks;
      formik.values.id = response.data.id;
      formik.values.taskLogin = response.data.taskLogin;
      setUser(response.data.id);
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const onSubmit = async (values: TenantSettingsEdit) => {
    try {
      const response = await ApiService.saveTenantSettings(values);
      setErrors(response.data);
      if (response.data.length > 0) {
        setIsValid(false);
      } else {
        setIsValid(true);
        window.location.replace("/");
      }
      console.log(response.data);
    } catch (e: any) {
      setIsValid(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      collaboration: "",
      collaborations: "",
      task: "",
      tasks: "",
      taskLogin: true,
    },
    onSubmit,
  });

  return (
    <>
      <div className="col-12 col-md-12">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="mb-1">Settings</h5>
            </div>
            <div className="pb-0">
              {!isValid &&
                errors.map((error: Error, index) => (
                  <Alert key={index} variant="warning">
                    {error.message}
                  </Alert>
                ))}
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="formCollaboration">
                  <Form.Label>Collaboration Label</Form.Label>
                  <Form.Control
                    name="collaboration"
                    value={formik.values.collaboration}
                    onChange={formik.handleChange}
                    type="text"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCollaboration">
                  <Form.Label>Collaborations Label</Form.Label>
                  <Form.Control
                    name="collaborations"
                    value={formik.values.collaborations}
                    onChange={formik.handleChange}
                    type="text"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCollaboration">
                  <Form.Label>Task Label</Form.Label>
                  <Form.Control
                    name="task"
                    value={formik.values.task}
                    onChange={formik.handleChange}
                    type="text"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCollaboration">
                  <Form.Label>Tasks Label</Form.Label>
                  <Form.Control
                    name="tasks"
                    value={formik.values.tasks}
                    onChange={formik.handleChange}
                    type="text"
                  />
                </Form.Group>                
                <div className="row mt-4">
                  <div className="col-md-6"></div>
                  <div className="col-md-6 text-right">
                    <Button variant="primary" type="submit">
                      Save Changes
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { TenantSettings };
