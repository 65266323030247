import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { useNavigate } from "react-router-dom";
import { AddTemplate } from "./templates-add";
import { AddTemplateAi } from "./templates-add-ai";
import { EditTemplate } from "./templates-edit";
import { ListTemplates } from "./templates-list";
import { useAuthUser } from "react-auth-kit";
import Spinner from "react-bootstrap/Spinner";
import { PaymentsWidget } from "../payments/payments_widget";

function Templates(props: any) {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [itemEdit, setItemEdit] = useState();
  const [itemsRefresh, setItemsRefresh] = useState(false);
  const [selectedAdd, setSelectedAdd] = useState(false);
  const [selectedAddAi, setSelectedAddAi] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(false);
  const [firstCollab, setFirstCollab] = useState(false);
  const [getItemsFlag, setGetItemsFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuthUser();

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [itemsRefresh, setItemsRefresh]);

  const getItems = async () => {
    setIsLoading(true);
    try {
      const response = await ApiService.getTemplates();
      setItems(response.data);
      setGetItemsFlag(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("Error");
    }
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-background">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <PaymentsWidget items={items} />
      <div className="col-12 col-md-12 mb-3">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>                
                {selectedAdd && <h5 className="mb-1">Add Your Own Template</h5>}
                {selectedAddAi && (
                  <h5 className="mb-1">Generate Smart Presentation Template</h5>
                )}
                {selectedEdit && <h5 className="mb-1">Edit Template</h5>}
              </div>
            </div>
            <div className="pb-0">
              {!selectedAdd && !selectedEdit && !selectedAddAi && (
                <ListTemplates
                  items={items}
                  itemsRefresh={setItemsRefresh}
                  setSelectedAdd={setSelectedAdd}
                  setSelectedAddAi={setSelectedAddAi}
                  setSelectedEdit={setSelectedEdit}
                  getItems={getItems}
                  setItemEdit={setItemEdit}
                  getItemsFlag={getItemsFlag}
                />
              )}
              {selectedEdit && (
                <EditTemplate
                  itemId={selectedEdit}
                  setSelectedItem={setSelectedEdit}
                  getItems={getItems}
                  itemEdit={itemEdit}
                />
              )}
              {selectedAdd && (
                <AddTemplate
                  displayItemAdd={setSelectedAdd}
                  getItems={getItems}
                  firstCollab={firstCollab}
                />
              )}
              {selectedAddAi && (
                <AddTemplateAi
                  setSelectedAddAi={setSelectedAddAi}
                  getItems={getItems}
                  firstCollab={firstCollab}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Templates };
