import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Error, UserEdit } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";
import { UserContext, User } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

function Profile() {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const auth = useAuthUser();
  const navigate = useNavigate();
  const [user, setUser] = useState();

  useEffect(() => {
    handleEditGet();
  }, []);

  useEffect(() => {
    handleEditGet();
  }, [user]);

  const handleEditGet = async () => {
    try {
        const response = await ApiService.editUserGet(auth()?.id);
        formik.values.firstname = response.data.firstName;
        formik.values.lastname = response.data.lastName;
        formik.values.roleid = response.data.roleId;
        formik.values.id = response.data.id;
        formik.values.email = response.data.email;
        setUser(response.data.id);
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const onSubmit = async (values: UserEdit) => {
    try {
      const response = await ApiService.editUser(values);
      setErrors(response.data);
      if (response.data.length > 0) {
        setIsValid(false);
      } else {
        setIsValid(true);
        window.location.replace("/");
      }
      console.log(response.data);
    } catch (e: any) {
      setIsValid(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      roleid: "",
      firstname: "",
      lastname: "",
      email: "",
    },
    onSubmit,
  });

  return (
    <>
      <div className="col-12 col-md-12">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="mb-1">User Profile</h5>
              </div>
            </div>
            <div className="pb-0">
              {!isValid &&
                errors.map((error: Error, index) => (
                  <Alert key={index} variant="warning">
                    {error.message}
                  </Alert>
                ))}
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicFirstName">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    name="firstname"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    type="text"
                    disabled={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="firstname"
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    placeholder="Enter first name"
                    type="text"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lastname"
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    placeholder="Enter last name"
                    type="text"
                  />
                </Form.Group>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <Button variant="primary-outline" onClick={() => navigate("/")}>
                      Go Back
                    </Button>
                  </div>
                  <div className="col-md-6 text-right">
                    <Button variant="primary" type="submit">
                      Save Changes
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Profile };
