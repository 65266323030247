import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

interface FilterProps {
  onFilterChange: (status: string) => void;
}

function FilterTasks(props: any) {
  return (
    <Form>
      <Form.Group controlId="filterSelect">
        <Form.Label>Filter by status</Form.Label>
        <Form.Select aria-label="Filter by status" onChange={e => props.onFilterChange(e.target.value)}>
          <option value="">All</option>
          <option value="In Progress">In Progress</option>
          <option value="Awaiting Approval">Awaiting Approval</option>
          <option value="Completed">Completed</option>
          <option value="Cancelled">Cancelled</option>
        </Form.Select>
      </Form.Group>
    </Form>
  );
};

export { FilterTasks };