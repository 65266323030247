import React, { useState, ChangeEvent, useContext } from "react";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import { Error } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";
import { useForm } from "react-hook-form";
import { UserContext } from "../../context/UserContext";

interface EditTemplate {
  id: string;
  name: string;
  description: string;
  isVisible: boolean;
  isTheme: boolean;
  isPublic: boolean;
  industry: string;
  category: string;
  complexity: string;
}

function EditTemplate(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const [editTemplate, setEditTemplate] = useState<EditTemplate>({
    id: props.itemEdit.id,
    name: props.itemEdit.name,
    description: props.itemEdit.description,
    category: props.itemEdit.category,
    industry: props.itemEdit.industry,
    isPublic: props.itemEdit.isPublic,
    isTheme: props.itemEdit.isTheme,
    isVisible: props.itemEdit.isVisible,
    complexity: props.itemEdit.complexity,
  });
  const [name, setName] = useState(props.itemEdit.name);
  const [description, setDescription] = useState(props.itemEdit.description);
  const [category, setCategory] = useState(props.itemEdit.category);
  const [industry, setIndustry] = useState(props.itemEdit.industry);
  const [isPublic, setIsPublic] = useState(props.itemEdit.isPublic);
  const [isTheme, setIsTheme] = useState(props.itemEdit.isTheme);
  const [isVisible, setIsVisible] = useState(props.itemEdit.isVisible);
  const [complexity, setComplexity] = useState(props.itemEdit.complexity);
  const { user } = useContext(UserContext);

  const hideItemEdit = () => {
    props.getItems();
    props.setSelectedItem(null);
  };

  const submitEditTemplate = () => {
    try {
      ApiService.editTemplate(editTemplate).then((response: any) => {
        if (response.data.length === 0) {
          props.getItems();
          hideItemEdit();
        } else {
          setErrors(response.data);
          setIsValid(false);
        }
      });
    } catch (e: any) {
      setIsValid(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setEditTemplate((prevProfile) => ({ ...prevProfile, [id]: value }));
  };

  return (
    <>
      {!isValid &&
        errors.map((error: Error, index) => (
          <Alert key={index} variant="warning">
            {error.message}
          </Alert>
        ))}
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name:
        </label>
        <input
          id="name"
          type="text"
          value={editTemplate.name}
          className="form-control"
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Description:
        </label>
        <textarea
          id="description"
          className="form-control"
          value={editTemplate.description}
          onChange={handleInputChange}
        />
      </div>
      {user?.istenantadmin && (
          <>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Industry:
              </label>
              <input
                id="industry"
                type="text"
                value={editTemplate.industry}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Category:
              </label>
              <input
                id="category"
                type="text"
                value={editTemplate.category}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isVisible"
                  checked={editTemplate.isVisible}
                  onChange={handleInputChange}                  
                />
                <label className="form-check-label" htmlFor="isVisible">
                  Is Visible
                </label>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isTheme"
                  checked={editTemplate.isTheme}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="isTheme">
                  Is Theme
                </label>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isPublic"
                  checked={editTemplate.isPublic}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="isPublic">
                  Is Public
                </label>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="complexity" className="form-label">
                Complexity:
              </label>
              <select
                id="complexity"
                value={editTemplate.complexity}
                onChange={handleInputChange}
                className="form-select"
              >
                <option value="1">Low</option>
                <option value="2">Medium</option>
                <option value="3">High</option>
              </select>
            </div>
          </>
        )}
      <div className="row mt-4">
        <div className="col-md-6">
          <Button variant="primary-outline" onClick={hideItemEdit}>
            Go Back
          </Button>
        </div>
        <div className="col-md-6 text-right">
          <Button variant="primary" onClick={() => submitEditTemplate()}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
}

export { EditTemplate };
