import React, { useEffect, useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import ApiService from "../../services/ApiService";
import { useNavigate } from "react-router-dom";
import { Collaborations } from "../collaborations/collaborations";
import { Tasks } from "../tasks/tasks";
import { TermsAndPrivacy } from "../common/termsandprivacy";
import { Tenant } from "./tenant";

function Dashboard() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [showTasks, setShowTasks] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [showWidget, setShowWidget] = useState(false);
  const [dashboardTriggerTasks, setDashboardTriggerTasks] = useState(0);
  const [dashboardTriggerCollabs, setDashboardTriggerCollabs] = useState(0);

  useEffect(() => {
    const onRefresh = async () => {
      const response2fa = await ApiService.isTwoFactor();
      if (response2fa.data === true) {
        navigate("/twofactor");
      }
    };
    onRefresh();
    getUserPrivacyApi();
    getUserTermsApi();
  }, []);

  const updateDashboard = () => {
    getUserPrivacyApi();
    getUserTermsApi();
  };

  const getUserPrivacyApi = () => {
    ApiService.getUserPrivacy().then((response: any) => {
      if (response.data == false) {
        setShowPrivacy(true);
        setShowWidget(true);
      } else {
        setShowPrivacy(false);
        if (showTerms == false) {
          setShowWidget(false);
        }
      }
    });
  };

  const getUserTermsApi = () => {
    ApiService.getUserTerms().then((response: any) => {
      if (response.data == false) {
        setShowTerms(true);
        setShowWidget(true);
      } else {
        setShowTerms(false);
        if (showPrivacy == false) {
          setShowWidget(false);
        }
      }
    });
  };

  return (
    <div>
      {!user?.istenantadmin && showWidget && (
        <TermsAndPrivacy onUpdate={setShowWidget} />
      )}
      {user?.isadmin && (
        <Tenant
          dashboardTriggerTasks={dashboardTriggerTasks}
          dashboardTriggerCollabs={dashboardTriggerCollabs}
        />
      )}
      {!user?.istenantadmin && !showWidget && showTasks && (
        <Tasks setDashboardTriggerTasks={setDashboardTriggerTasks} />
      )}      
      {!showWidget && (
        <Collaborations
          setDashboardTriggerCollabs={setDashboardTriggerCollabs}
          setTasksFlag={setShowTasks}
        />
      )}
      {/* <Templates /> */}
    </div>
  );
}

export { Dashboard };
