/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function TermsAndPrivacy(props: any) {
  const [showWidget, setShowWidget] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [acPrivacy, setAcPrivacy] = useState(false);
  const [acTerms, setAcTerms] = useState(false);

  const getUserPrivacyApi = () => {
    ApiService.getUserPrivacy().then((response: any) => {
      if (response.data == false) {
        setShowPrivacy(true);
        setShowWidget(true);
      } else {
        setShowPrivacy(false);
        if (showTerms == false) {
          setShowWidget(false);
          props.onUpdate(false);
        }
      }
    });
  };

  const getUserTermsApi = () => {
    ApiService.getUserTerms().then((response: any) => {
      if (response.data == false) {
        setShowTerms(true);
        setShowWidget(true);
      } else {
        setShowTerms(false);
        if (showPrivacy == false) {
          setShowWidget(false);
          props.onUpdate(false);
        }
      }
    });
  };

  const setUserPrivacyApi = () => {
    ApiService.setUserPrivacy().then((response: any) => {
      if (response.data == true) {
        setShowPrivacy(false);
        if (showTerms == false) {
          setShowWidget(false);
          props.onUpdate(false);
        }
      }
    });
  };

  const setUserTermsApi = () => {
    ApiService.setUserTerms().then((response: any) => {
      if (response.data == true) {
        setShowTerms(false);
        if (showPrivacy == false) {
          setShowWidget(false);
          props.onUpdate(false);
        }
      }
    });
  };

  const handleAcknowledgePrivacy = () => {
    setUserPrivacyApi();
    setShowModalPrivacy(false);
    setAcPrivacy(true);
    getUserPrivacyApi();
  };

  const handleAcknowledgeTerms = () => {
    setUserTermsApi();
    setShowModalTerms(false);
    setAcTerms(true);
    getUserTermsApi();
  };

  const handleAcknowledgePrivacyClose = () => {
    setShowModalPrivacy(false);
  };

  const handleAcknowledgeTermsClose = () => {
    setShowModalTerms(false);
  };

  useEffect(() => {
    getUserPrivacyApi();
    getUserTermsApi();
  }, []);

  return (
    <>
      {showWidget && (
        <div className="col-12 col-md-12 mb-3">
          <div className="alert alert-primary" role="alert">
            In order to proceed please open and acknowledge the following:
            {!acPrivacy && (
              <div className="row">
                <a
                  href="#"
                  onClick={() => setShowModalPrivacy(true)}
                  className="mouse-pointer"
                >
                  Privacy Policy
                </a>
              </div>
            )}
            {!acTerms && (
              <div className="row">
                <a
                  href="#"
                  onClick={() => setShowModalTerms(true)}
                  className="mouse-pointer"
                >
                  Terms and Conditions
                </a>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        show={showModalPrivacy}
        onHide={handleAcknowledgePrivacyClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <embed
            src="https://www.slidecollab.com/documents/privacy_policy.pdf"
            width="100%"
            height="500px"
            type="application/pdf"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAcknowledgePrivacy}>
            Acknowledge Privacy Policy
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalTerms}
        onHide={handleAcknowledgeTermsClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <embed
            src="https://www.slidecollab.com/documents/terms_and_conditions.pdf"
            width="100%"
            height="500px"
            type="application/pdf"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAcknowledgeTerms}>
            Acknowledge Terms and Conditions
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { TermsAndPrivacy };
