import React, { useEffect, useContext, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Login } from "./components/user/login";
import { Signup } from "./components/user/signup";
import { ResetPassword } from "./components/user/resetpassword";
import { ResetPasswordRequest } from "./components/user/resetpasswordrequest";
import { TwoFactor } from "./components/user/twofactor";
import { ActivateAccount } from "./components/user/activateaccount";
import { Invite } from "./components/user/invite";
import { Dashboard } from "./components/dashboard/dashboard";
import { DashboardTenants } from "./components/dashboard/dashboard-tenants";
import { Users } from "./components/admin/users";
import { Profile } from "./components/user/profile";
import { ChangePassword } from "./components/user/changepassword";
import { TenantSettings } from "./components/admin/tenant-settings";
import { Templates } from "./components/templates/templates";
import { Payments } from "./components/payments/payments";
import {
  RequireAuth,
  useSignOut,
  useIsAuthenticated,
  useSignIn,
  useAuthUser,
} from "react-auth-kit";
import { UserContext, User } from "./context/UserContext";
import Cookies from "js-cookie";
import ApiService from "./services/ApiService";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import "./assets/css/theme.css";
import "./css/app.css";
import logo from "./assets/images/logo.png";
import { useGlobalContext } from "./GlobalContext";
import {
  PeopleFill,
  FilePptFill,
  Diagram3Fill,
  WalletFill,
} from "react-bootstrap-icons";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const singOut = useSignOut();
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const signIn = useSignIn();
  const [currentpath, setCurrentPath] = useState("/");
  const auth = useAuthUser();
  const context = useGlobalContext();
  const [isPhone, setIsPhone] = useState(window.innerWidth < 768);

  useEffect(() => {
    const onPageLoad = async () => {
      try {
        const response = await ApiService.userInfo();
        const user: User = {
          id: response.data.user.id,
          name: response.data.user.fullName,
          email: response.data.user.email,
          isadmin: response.data.user.isAdmin,
          istenantadmin: response.data.user.isTenantAdmin,
          istwoFactor: response.data.user.isTwoFactor,
        };
        setUser(user);
        signIn({
          token: response.data,
          expiresIn: 3600,
          tokenType: "Bearer",
          authState: {
            email: response.data.user.email,
            user: response.data.user,
            id: response.data.user.id,
            transCollab: response.data.user.transCollaboration,
            transCollabs: response.data.user.transCollaborations,
            transTask: response.data.user.transTask,
            transTasks: response.data.user.transTasks,
          },
        });
      } catch (e) {
        console.log("Error");
        await logout();
      }
    };
    const onRefresh = async () => {
      const response2fa = await ApiService.isTwoFactor();
      if (response2fa.data === true) {
        navigate("/twofactor");
      }
    };
    if (user == null && Cookies.get("_webapp_client_jwt") != null) {
      setCurrentPath("/");
      onPageLoad();
    } else {
      singOut();
    }
    fetch("/config.json")
      .then((response) => response.json())
      .then((config) => {
        context.setApiUrl(config.apiUrl);
      });
    onRefresh();

    const handleResize = () => {
      setIsPhone(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const logout = async () => {
    try {
      await ApiService.logout();
    } catch (e: any) {
      console.log("Error -> Logout -->" + e.toJSON().message);
    }
    singOut();
    Cookies.remove("_webapp_client_jwt");
    window.location.replace("/login");
  };

  function getMenuItems() {
    if (isAuthenticated()) {
      return (
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              className={currentpath === "/" ? "nav-link fw-bold" : "nav-link"}
              aria-current="page"
              to={"/"}
              onClick={() => setCurrentPath("/")}
              title="Templates"
            >
              {isPhone && (
                <>
                  <FilePptFill size={25} />
                </>
              )}
              {!isPhone && <>Templates</>}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                currentpath === "/dashboard" ? "nav-link fw-bold" : "nav-link"
              }
              aria-current="page"
              to={"/dashboard"}
              onClick={() => setCurrentPath("/dashboard")}
              title={auth()?.transCollab}
            >
              {isPhone && (
                <>
                  <Diagram3Fill size={25} />
                </>
              )}
              {!isPhone && <>{auth()?.transCollab}</>}
            </Link>
          </li>
          {user?.isadmin && (
            <>
              <li className="nav-item">
                <Link
                  className={
                    currentpath === "/users" ? "nav-link fw-bold" : "nav-link"
                  }
                  aria-current="page"
                  to={"/users"}
                  onClick={() => setCurrentPath("/users")}
                  title="Users"
                >
                  {isPhone && (
                    <>
                      <PeopleFill size={25} />
                    </>
                  )}
                  {!isPhone && <>Users</>}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    currentpath === "/payments"
                      ? "nav-link fw-bold"
                      : "nav-link"
                  }
                  aria-current="page"
                  to={"/payments"}
                  onClick={() => setCurrentPath("/payments")}
                  title="Payments"
                >
                  {isPhone && (
                    <>
                      <WalletFill size={25} />
                    </>
                  )}
                  {!isPhone && <>Payments</>}
                </Link>
              </li>
            </>
          )}
          {user?.istenantadmin && (
            <>
              <li className="nav-item">
                <Link
                  className={
                    currentpath === "/tenants" ? "nav-link fw-bold" : "nav-link"
                  }
                  aria-current="page"
                  to={"/tenants"}
                  onClick={() => setCurrentPath("/tenants")}
                >
                  Tenants
                </Link>
              </li>
            </>
          )}
        </ul>
      );
    } else {
      return (
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" aria-current="page" to={"#"}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" aria-current="page" to={"/login"}>
              Sign In
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" aria-current="page" to={"/signup"}>
              Sign Up
            </Link>
          </li>
        </ul>
      );
    }
  }

  function getLogoutAction() {
    if (isAuthenticated()) {
      return (
        <NavDropdown
          id="nav-dropdown-dark-example"
          title={user?.name}
          menuVariant="light"
        >
          <NavDropdown.Item href="/profile">Edit Profile</NavDropdown.Item>
          <NavDropdown.Item href="/changepassword">
            Change Password
          </NavDropdown.Item>
          {user?.istenantadmin && (
            <NavDropdown.Item href="/tenantsettings">
              Tenant Settings
            </NavDropdown.Item>
          )}
          <NavDropdown.Divider />
          <NavDropdown.Item href="#" onClick={logout}>
            Log Out
          </NavDropdown.Item>
        </NavDropdown>
      );
    } else {
      return <span></span>;
    }
  }

  return (
    <>
      <Navbar className="navbar navbar-top navbar-expand-lg">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">{getMenuItems()}</Nav>
            <Nav>{getLogoutAction()}</Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-3">
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/resetpassword" element={<ResetPassword />}></Route>
          <Route path="/twofactor" element={<TwoFactor />}></Route>
          <Route path="/activateaccount" element={<ActivateAccount />}></Route>
          <Route path="/invite" element={<Invite />}></Route>
          <Route
            path="/resetpasswordrequest"
            element={<ResetPasswordRequest />}
          ></Route>
          <Route
            path="/"
            element={
              <RequireAuth loginPath="/login">
                <Templates />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/dashboard"
            element={
              <RequireAuth loginPath="/login">
                <Dashboard />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/users"
            element={
              <RequireAuth loginPath="/login">
                <Users />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/tenantsettings"
            element={
              <RequireAuth loginPath="/login">
                <TenantSettings />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/profile"
            element={
              <RequireAuth loginPath="/login">
                <Profile />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/changepassword"
            element={
              <RequireAuth loginPath="/changepassword">
                <ChangePassword />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/tenants"
            element={
              <RequireAuth loginPath="/tenants">
                <DashboardTenants />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/payments"
            element={
              <RequireAuth loginPath="/payments">
                <Payments />
              </RequireAuth>
            }
          ></Route>
        </Routes>
      </Container>
    </>
  );
}

export default App;
