import React, { useState } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import ValidationService from "../../services/ValidationService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Error } from "../../context/UserContext";

function Invite(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [isReset, setIsReset] = useState(false);
  const [errors, setErrors] = useState([] as Error[]);

  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  const onSubmit = async (values: any) => {
    if (ValidationService.checkPassword(values.password).length > 0) {
      setIsValid(false);
      setErrors([
        {
          type: "password",
          message:
            "Password validation error - " +
            ValidationService.checkPassword(values.password),
          stackTrace: "",
        },
      ]);
    } else {
      try {
        const response = await ApiService.inviteUserRegister(values);
        console.log("TokenDto Data: ");
        setIsReset(true);
      } catch (err) {
        setIsReset(true);
        setIsValid(false);
        console.log("Error: ", err);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      token: token,
      password: "",
      firstname: "",
      lastname: "",
    },
    onSubmit,
  });

  return (
    <div>
      <div className="card mx-auto col-md-6">
        <div className="card-body">
          <h3>User Invitation</h3>
          <span className="p-3"></span>
          {!isValid &&
            errors.map((error: Error, index) => (
              <Alert key={index} variant="warning">
                {error.message}
              </Alert>
            ))}
          {!isReset && (
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  placeholder="Password"
                  type="password"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  name="firstname"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  placeholder="Enter first name"
                  type="text"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  placeholder="Enter last name"
                  type="text"
                />
              </Form.Group>
              <Button variant="primary-outline" type="submit">
                Register
              </Button>
            </Form>
          )}
          {isReset && (
            <Alert variant="success">
              Your registration is now complete. <br />
              Please login with your username and password.
              <br />
              <span className="pt-5"></span>
              <a href="/login">Login</a>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
}

export { Invite };
