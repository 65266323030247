import React, { useState } from "react";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import { Error } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";
import { useForm } from "react-hook-form";
import { useAuthUser } from "react-auth-kit";
import Spinner from "react-bootstrap/Spinner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { AddCollaborationTemplate } from "./collaborations-add-template";

type FormData = {
  name: string;
  description: string;
  file: FileList;
};

function AddCollaboration(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const { register, handleSubmit } = useForm<FormData>();
  const [uploadStatus, setUploadStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showTemplates, setShowTemplates] = useState(true);
  const auth = useAuthUser();

  const hideItemAdd = () => {
    props.getItems();
    props.displayItemAdd(false);
  };

  const onSubmit = async (data: FormData) => {
    if (!data.name) {
      setIsValid(false);
      setErrors([
        {
          type: "password",
          message: "A name is required",
          stackTrace: "",
        },
      ]);
    } else if (data.file.length === 0) {
      setIsValid(false);
      setErrors([
        {
          type: "password",
          message: "A file is required",
          stackTrace: "",
        },
      ]);
    } else {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("file", data.file[0]);
        const response = await ApiService.addCollaboration(formData);
        setErrors(response.data);
        setIsLoading(false);
        if (response.data.length > 0) {
          setIsValid(false);
        } else {
          console.log("File Uploaded!");
          props.getItems();
          hideItemAdd();
        }
      } catch (e: any) {
        setIsValid(false);
        setIsLoading(false);
        console.log("Error -> " + e.toJSON().message);
      }
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey="template"
        id="uncontrolled-tab-example"
        className="mb-3 mt-3"
      >
        <Tab eventKey="template" title="Existing Templates">
          {showTemplates && (
            <AddCollaborationTemplate
              hideItemAdd={hideItemAdd}
              setShowTemplates={setShowTemplates}
            />
          )}
          {!showTemplates && (
            <div className="alert alert-info" role="alert">
              There are no templates available. Please upload a file to create a
              new collaboration.
            </div>
          )}
        </Tab>
        <Tab eventKey="collab" title="Upload File">
          {!isValid &&
            errors.map((error: Error, index) => (
              <Alert key={index} variant="warning">
                {error.message}
              </Alert>
            ))}
          <form onSubmit={handleSubmit(onSubmit)}>
            {isLoading && (
              <div className="spinner-background">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name:
              </label>
              <input
                id="name"
                type="text"
                {...register("name")}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description:
              </label>
              <textarea
                id="description"
                {...register("description")}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="file" className="form-label">
                File:
              </label>
              <input
                id="file"
                type="file"
                {...register("file")}
                className="form-control"
              />
            </div>
            <div>{uploadStatus}</div>
            <div className="mb-3 mt-3">
              <div className="alert alert-info" role="alert">
                Please note that based on file size, it may take a few minutes
                to upload and process. Please be patient.
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <Button
                  variant="primary-outline"
                  onClick={hideItemAdd}
                  hidden={props.firstCollab}
                >
                  Go Back
                </Button>
              </div>
              <div className="col-md-6 text-right">
                <Button variant="primary" type="submit" disabled={isLoading}>
                  Start {auth()?.transCollab}
                </Button>
              </div>
            </div>
          </form>
        </Tab>
      </Tabs>
    </>
  );
}

export { AddCollaboration };
