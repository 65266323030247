import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the shape of your context
interface GlobalContextType {
  apiUrl: string;
  setApiUrl: (value: string) => void;
}

// Create the context
const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

// Create a custom hook for easier consumption of the context
export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
}

// Define the type for the props of GlobalProvider
interface GlobalProviderProps {
  children: ReactNode; // This is the key change
}

// Create the provider component
export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [apiUrl, setApiUrl] = useState<string>('initialValue');

  return (
    <GlobalContext.Provider value={{ apiUrl, setApiUrl }}>
      {children}
    </GlobalContext.Provider>
  );
}