import React, { useState, useContext } from "react";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import { Error } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";
import { useForm } from "react-hook-form";
import { useAuthUser } from "react-auth-kit";
import Spinner from "react-bootstrap/Spinner";
import { UserContext } from "../../context/UserContext";

type FormData = {
  name: string;
  description: string;
  file: FileList;
  isVisible: boolean;
  isTheme: boolean;
  isPublic: boolean;
  industry: string;
  category: string;
  complexity: string;
};

function AddTemplate(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const { register, handleSubmit } = useForm<FormData>();
  const [uploadStatus, setUploadStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuthUser();
  const { user } = useContext(UserContext);

  const hideItemAdd = () => {
    props.getItems();
    props.displayItemAdd(false);
  };

  const onSubmit = async (data: FormData) => {
    if (!data.name) {
      setIsValid(false);
      setErrors([
        {
          type: "password",
          message: "A name is required",
          stackTrace: "",
        },
      ]);
    } else if (data.file.length === 0) {
      setIsValid(false);
      setErrors([
        {
          type: "password",
          message: "A file is required",
          stackTrace: "",
        },
      ]);
    } else {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("file", data.file[0]);
        if (user?.istenantadmin) {
          formData.append("isVisible", data.isVisible.toString());
          formData.append("isTheme", data.isTheme.toString());
          formData.append("isPublic", data.isPublic.toString());
          formData.append("industry", data.industry);
          formData.append("category", data.category);
          formData.append("complexity", data.complexity);
        }
        const response = await ApiService.addTemplate(formData);
        setErrors(response.data);
        setIsLoading(false);
        if (response.data.length > 0) {
          setIsValid(false);
        } else {
          console.log("File Uploaded!");
          props.getItems();
          hideItemAdd();
        }
      } catch (e: any) {
        setIsValid(false);
        setIsLoading(false);
        console.log("Error -> " + e);
      }
    }
  };

  return (
    <>
      {!isValid &&
        errors.map((error: Error, index) => (
          <Alert key={index} variant="warning">
            {error.message}
          </Alert>
        ))}
      {props.firstTemplate && (
        <div className="alert alert-primary mt-3" role="alert">
          Thank you for registering! Please add your first template.
          <br />
          Enter a name and select the file to upload.
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        {isLoading && (
          <div className="spinner-background">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name (required):
          </label>
          <input
            id="name"
            type="text"
            {...register("name")}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description:
          </label>
          <textarea
            id="description"
            {...register("description")}
            className="form-control"
          />
        </div>
        {user?.istenantadmin && (
          <>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Industry:
              </label>
              <input
                id="industry"
                type="text"
                {...register("industry")}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Category:
              </label>
              <input
                id="category"
                type="text"
                {...register("category")}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isVisible"
                  {...register("isVisible")}
                />
                <label className="form-check-label" htmlFor="isVisible">
                  Is Visible
                </label>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isTheme"
                  {...register("isTheme")}
                />
                <label className="form-check-label" htmlFor="isTheme">
                  Is Theme
                </label>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isPublic"
                  {...register("isPublic")}
                />
                <label className="form-check-label" htmlFor="isPublic">
                  Is Public
                </label>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="complexity" className="form-label">
                Complexity:
              </label>
              <select
                id="complexity"
                {...register("complexity")}
                className="form-select"
              >
                <option value="1">Low</option>
                <option value="2">Medium</option>
                <option value="3">High</option>
              </select>
            </div>
          </>
        )}
        <div className="mb-3">
          <label htmlFor="file" className="form-label">
            File (required):
          </label>
          <input
            id="file"
            type="file"
            {...register("file")}
            className="form-control"
          />
        </div>
        <div>{uploadStatus}</div>
        <div className="mb-3 mt-3">
          <div className="alert alert-info" role="alert">
            Please note that based on file size, it may take a few minutes to
            upload and process. Please be patient.
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <Button
              variant="primary-outline"
              onClick={hideItemAdd}
              hidden={props.firstTemplate}
            >
              Go Back
            </Button>
          </div>
          <div className="col-md-6 text-right">
            <Button variant="primary" type="submit" disabled={isLoading}>
              Add Template
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export { AddTemplate };
