import React, { useEffect, useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import ApiService from "../../services/ApiService";
import { useNavigate } from "react-router-dom";
import { Tenants } from "./tenants";
import { TenantsActivity } from "./tenants-activity";

function DashboardTenants() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const onRefresh = async () => {
      const response2fa = await ApiService.isTwoFactor();
      if (response2fa.data === true) {
        navigate("/twofactor");
      }
    };
    onRefresh();
  }, []);

  return (
    <div>
      {user?.istenantadmin && <TenantsActivity />}
      {user?.istenantadmin && <Tenants />}      
    </div>
  );
}

export { DashboardTenants };
