import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Error, ChangePasswordEdit } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

function ChangePassword() {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const auth = useAuthUser();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    
  }, [user]);

  const onSubmit = async (values: ChangePasswordEdit) => {
    try {
      const response = await ApiService.changePassword(values);
      setErrors(response.data);
      if (response.data.length > 0) {
        setIsValid(false);
      } else {
        setIsValid(true);
        window.location.replace("/");
      }
      console.log(response.data);
    } catch (e: any) {
      setIsValid(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const formik = useFormik({
    initialValues: {
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
    },
    onSubmit,
  });

  return (
    <>
      <div className="col-12 col-md-12">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="mb-1">Change Password</h5>
              </div>
            </div>
            <div className="pb-0">
              {isComplete && (
                <span>
                  <Alert variant="info">The password has been updated.</Alert>
                </span>
              )}
              {!isValid &&
                errors.map((error: Error, index) => (
                  <Alert key={index} variant="warning">
                    {error.message}
                  </Alert>
                ))}
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="formCurrentPassword">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    name="oldpassword"
                    value={formik.values.oldpassword}
                    onChange={formik.handleChange}
                    placeholder="Enter current password"
                    type="password"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formNewPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    name="newpassword"
                    value={formik.values.newpassword}
                    onChange={formik.handleChange}
                    placeholder="Enter new password"
                    type="password"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formNewPasswordConfirm">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    name="confirmpassword"
                    value={formik.values.confirmpassword}
                    onChange={formik.handleChange}
                    placeholder="Re-enter new password"
                    type="password"
                  />
                </Form.Group>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <Button
                      variant="primary-outline"
                      onClick={() => navigate("/")}
                    >
                      Go Back
                    </Button>
                  </div>
                  <div className="col-md-6 text-right">
                    <Button variant="primary" type="submit">
                      Change Password
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { ChangePassword };
