import React, { useState } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import ValidationService from "../../services/ValidationService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Error, UserAdd } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";

function AddUser(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const [isComplete, setIsComplete] = useState(false);

  const onSubmit = async (values: UserAdd) => {
    if (ValidationService.checkPassword(values.password).length > 0) {
      setIsValid(false);
      setErrors([
        {
          type: "password",
          message:
            "Password validation error - " +
            ValidationService.checkPassword(values.password),
          stackTrace: "",
        },
      ]);
    } else if (ValidationService.validateEmail(values.email).length > 0) {
      setIsValid(false);
      setErrors([
        {
          type: "email",
          message:
            "Email validation error - " +
            ValidationService.validateEmail(values.email),
          stackTrace: "",
        },
      ]);
    } else {
      try {
        const response = await ApiService.addUser(values);
        setErrors(response.data);
        if (response.data.length > 0) {
          setIsValid(false);
        } else {
          setIsComplete(true);
          values.email = "";
          values.password = "";
          values.firstname = "";
          values.lastname = "";
        }
        console.log(response.data);
      } catch (e: any) {
        setIsValid(false);
        console.log("Error -> " + e.toJSON().message);
      }
    }
  };

  const resetForm = () => {
    setIsValid(true);
    setIsComplete(false);
    setErrors([]);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstname: "",
      lastname: "",
    },
    onSubmit,
  });

  const hideUserAdd = () => {
    props.getUsers();
    props.displayUserAdd(false);
  };

  return (
    <div>
      {!isValid &&
        errors.map((error: Error, index) => (
          <Alert key={index} variant="warning">
            {error.message}
          </Alert>
        ))}
      {isComplete && (
        <span>
          <Alert variant="success">The account has been created.</Alert>
          <div className="row mt-4">
            <div className="col-md-6">
              <Button variant="primary-outline" onClick={hideUserAdd}>
                Go Back
              </Button>
            </div>
            <div className="col-md-6 text-right">
              <Button variant="primary" onClick={resetForm}>
                Add Another
              </Button>
            </div>
          </div>
        </span>
      )}
      {!isComplete && (
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="Enter email"
              type="email"
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              placeholder="Password"
              type="password"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="firstname"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              placeholder="Enter first name"
              type="text"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="lastname"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              placeholder="Enter last name"
              type="text"
            />
          </Form.Group>
          <div className="row mt-4">
            <div className="col-md-6">
              <Button variant="primary-outline" onClick={hideUserAdd}>
                Go Back
              </Button>
            </div>
            <div className="col-md-6 text-right">
              <Button variant="primary" type="submit">
                Add User
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export { AddUser };
