/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

function SlidesDisplaySimple(props: any) {
  const [slidesize, setSlidesize] = useState("col-md-2");

  useEffect(() => {
    if (props.initialSize) {
      setSlidesize(props.initialSize);
    }
  }, []);

  return (
    <>
    
      <div>
        <div className="row">
          <div className=" mb-3">
            <a
              href="#"
              className={slidesize === "col-md-2" ? " fw-bold" : ""}
              onClick={() => setSlidesize("col-md-2")}
            >
              Small
            </a>{" "}
            -{" "}
            <a
              href="#"
              className={slidesize === "col-md-4" ? " fw-bold" : ""}
              onClick={() => setSlidesize("col-md-4")}
            >
              Medium
            </a>{" "}
            -{" "}
            <a
              href="#"
              className={slidesize === "col-md-6" ? " fw-bold" : ""}
              onClick={() => setSlidesize("col-md-6")}
            >
              Large
            </a>{" "}
            -{" "}
            <a
              href="#"
              className={slidesize === "col-md-12" ? " fw-bold" : ""}
              onClick={() => setSlidesize("col-md-12")}
            >
              XLarge
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">            
            <div className="row">
              {props.slides.map(
                (item: any, index: number) => (
                  <div key={index} className={slidesize}>
                    <img
                      src={item}
                      alt="Thumbnail"
                      className="slideImage mb-1"
                    />
                  </div>
                ),
                []
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { SlidesDisplaySimple };
