import React, { useState } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Error, InviteAdd } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";

function InviteUser(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const [isComplete, setIsComplete] = useState(false);

  const onSubmit = async (values: InviteAdd) => {
    try {
      const response = await ApiService.inviteUser(values);
      setErrors(response.data);
      if (response.data.length > 0) {
        setIsValid(false);
      } else {
        setIsComplete(true);
        values.email = "";
      }
      console.log(response.data);
    } catch (e: any) {
      setIsValid(false);
      console.log("Error ->" + e.toJSON().message);
    }
  };

  const resetForm = () => {
    setIsValid(true);
    setIsComplete(false);
    setErrors([]);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit,
  });

  const hideUserInvite = () => {
    props.getUsers();
    props.displayUserInvite(false);
  };

  return (
    <div>
      {!isValid &&
        errors.map((error: Error, index) => (
          <Alert key={index} variant="warning">
            {error.message}
          </Alert>
        ))}
      {isComplete && (
        <span>
          <Alert variant="success">The user has been invited.</Alert>

          <div className="row mt-4">
            <div className="col-md-6">
              <Button variant="primary-outline" onClick={hideUserInvite}>
                Go Back
              </Button>
            </div>
            <div className="col-md-6 text-right">
              <Button variant="primary" onClick={resetForm}>
                Invite Another
              </Button>
            </div>
          </div>
        </span>
      )}
      {!isComplete && (
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="Enter email"
              type="email"
            />
          </Form.Group>
          <div className="row mt-4">
            <div className="col-md-6">
              <Button variant="primary-outline" onClick={hideUserInvite}>
                Go Back
              </Button>
            </div>
            <div className="col-md-6 text-right">
              <Button variant="primary" type="submit">
                Invite User
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export { InviteUser };
