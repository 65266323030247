import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { useNavigate } from "react-router-dom";
import { UsersList } from "../admin/users-list";
import { AddUser } from "../admin/users-adduser";
import { InviteUser } from "../admin/users-inviteuser";
import { EditUser } from "./users-edit";

function Users() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [usersRefresh, setUsersRefresh] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserAdd, setSelectedUserAdd] = useState(false);
  const [selectedUserInvite, setSelectedUserInvite] = useState(false);

  useEffect(() => {
    onRefresh();
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [usersRefresh, setUsersRefresh]);

  const getUsers = async () => {
    try {
      const response = await ApiService.getUsers();
      setUsers(response.data);
    } catch (e) {
      console.log("Error");
    }
  };

  const onRefresh = async () => {
    const response2fa = await ApiService.isTwoFactor();
    if (response2fa.data === true) {
      navigate("/twofactor");
    }
  };

  const editUser = (user: any) => {
    setSelectedUser(user.id);
  };

  const addUser = (display : boolean) => {
    setSelectedUserAdd(display);
  };

  const inviteUser = (display : boolean) => {
    setSelectedUserInvite(display);
  };

  return (
    <>
      <div className="col-12 col-md-12">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                {selectedUser && <h5 className="mb-1">Edit User</h5>}
                {selectedUserAdd && <h5 className="mb-1">Add User</h5>}
                {selectedUserInvite && <h5 className="mb-1">Invite User</h5>}
              </div>
            </div>
            <div className="pb-0">
              {!selectedUser && !selectedUserAdd && !selectedUserInvite && (
                <UsersList
                  users={users}
                  usersRefresh={setUsersRefresh}
                  onEditUser={editUser}
                  setSelectedUserAdd={addUser}
                  setSelectedUserInvite={inviteUser}
                />
              )}
              {selectedUser && (
                <EditUser
                  userId={selectedUser}
                  setSelectedUser={setSelectedUser}
                  getUsers={getUsers}
                />
              )}
              {selectedUserAdd && (
                <AddUser
                  displayUserAdd={addUser}
                  getUsers={getUsers}
                />
              )}
              {selectedUserInvite && (
                <InviteUser
                  displayUserInvite={inviteUser}
                  getUsers={getUsers}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Users };
