import React, { useState } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import ValidationService from "../../services/ValidationService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Error } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSignOut } from "react-auth-kit";

function TwoFactor(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const navigate = useNavigate();
  const singOut = useSignOut();

  const onSubmit = async (values: any) => {
    if (ValidationService.checkPassword(values.code).length === 0) {
      setIsValid(false);
      setErrors([
        {
          type: "code",
          message: "Two Factor validation error - cannot be empty.",
          stackTrace: "",
        },
      ]);
    } else {
      try {
        const response = await ApiService.validatetwofactor(values);
        navigate("/");
      } catch (err) {
        setErrors([
          {
            type: "code",
            message: "Two Factor validation error - cannot validate code.",
            stackTrace: "",
          },
        ]);
        values.code = "";
        setIsValid(false);
        console.log("Error: ", err);
      }
    }
  };

  const logout = async () => {
    try {
      await ApiService.logout();
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
    }
    logout();
    singOut();
    Cookies.remove("_webapp_client_jwt");
    window.location.replace("/login");
  };

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit,
  });

  return (
    <div className="container-fluid px-0">
      <div className="container">
        <div className="row flex-center py-5">
          <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
            <h3>Two Factor Authentication</h3>
            <span className="p-3"></span>
            {!isValid &&
              errors.map((error: Error, index) => (
                <Alert key={index} variant="warning">
                  {error.message}
                </Alert>
              ))}
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  placeholder="2FA Code"
                  type="text"
                />
              </Form.Group>
              <Button type="submit" className="btn btn-primary w-100 mb-3">
                Continue
              </Button>
              <button type="button" className="btn btn-outline-secondary w-100 mb-3" onClick={logout}>
                Logout
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export { TwoFactor };
