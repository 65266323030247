import React, { useState } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import ValidationService from "../../services/ValidationService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Error } from "../../context/UserContext";

function ResetPassword(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [isReset, setIsReset] = useState(false);
  const [errors, setErrors] = useState([] as Error[]);

  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  const onSubmit = async (values: any) => {
    if (ValidationService.checkPassword(values.password).length > 0) {
      setIsValid(false);
      setErrors([
        {
          type: "password",
          message:
            "Password validation error - " +
            ValidationService.checkPassword(values.password),
          stackTrace: "",
        },
      ]);
    } else {
      try {
        const response = await ApiService.resetPassword(values);
        console.log("TokenDto Data: ");
        setIsReset(true);
        setIsValid(true);
      } catch (err) {
        setIsReset(true);
        setIsValid(false);
        console.log("Error: ", err);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      token: token,
      password: "",
    },
    onSubmit,
  });

  return (
    <div className="container-fluid px-0">
      <div className="container">
        <div className="row flex-center py-5">
          <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
            <h3>Password Reset</h3>
            <span className="p-3"></span>
            {!isValid &&
              errors.map((error: Error, index) => (
                <Alert key={index} variant="warning">
                  {error.message}
                </Alert>
              ))}
            {!isReset && (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder="Password"
                    type="password"
                  />
                </Form.Group>
                <Button type="submit" className="btn btn-primary w-100 mb-3">
                  Reset Password
                </Button>
              </Form>
            )}
            {isReset && (
              <Alert variant="success">
                Your password has been reset. <br />
                Please login with your new password.
                <br />
                <span className="pt-5"></span>
                <a href="/login">Login</a>
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { ResetPassword };
