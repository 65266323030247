import React, { useState, useEffect, PureComponent } from "react";
import ApiService from "../../services/ApiService";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function TenantsActivity(props: any) {
  const [tenantsActivity, setTenantsActivity] = useState([]);

  useEffect(() => {
    getTenantsActivity();
  }, []);

  const getTenantsActivity = async () => {
    try {
      const response = await ApiService.getDashboardTenantsActivity();
      setTenantsActivity(response.data);
    } catch (e) {
      console.log("Error");
    }
  };

  return (
    <>
      <div className="col-12 col-md-12">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="mb-1">Tenant Activity</h5>
                <h6 className="text-700">Last 30 days</h6>
              </div>
            </div>
            <div className="pb-0 pt-4">
              <div style={{ width: "100%", height: 300 }}>
                <ResponsiveContainer>
                  <LineChart
                    data={tenantsActivity}
                    width={500}
                    height={300}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="activityCount"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                      name="Activity"
                    />                    
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-12">
        &nbsp;
      </div>
      <div className="col-12 col-md-12">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="mb-1">New Users</h5>
                <h6 className="text-700">Last 30 days</h6>
              </div>
            </div>
            <div className="pb-0 pt-4">
              <div style={{ width: "100%", height: 300 }}>
                <ResponsiveContainer>
                  <LineChart
                    data={tenantsActivity}
                    width={500}
                    height={300}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />                   
                    <Line
                      type="monotone"
                      dataKey="newUsersCount"
                      stroke="#82ca9d"
                      activeDot={{ r: 8 }}
                      name="New Users"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { TenantsActivity };
