import React, { useState } from "react";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Sliders,
  CloudDownload,
  ArrowUp,
  ArrowDown,
} from "react-bootstrap-icons";
import { Files } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { useAuthUser } from "react-auth-kit";
import { SlidesDisplay } from "../common/slides-display";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { useGlobalContext } from "../../GlobalContext";
import PPTXIcon from "../../assets/images/pptx.jpg";

function ListCollaboration(props: any) {
  const [showImages, setShowImages] = useState(false);
  const [showCollabDeleteConfirm, setShowCollabDeleteConfirm] = useState(false);
  const [collaboration, setCollaboration] = useState();
  const [collaborationId, setCollaborationId] = useState();
  const [images, setImages] = useState([]);
  const [imagesOriginal, setImagesOriginal] = useState([]);
  const [errorsDelete, setErrorsDelete] = useState([] as Error[]);
  const [isValidDelete, setIsValidDelete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuthUser();
  const context = useGlobalContext();
  const [sortColumn, setSortColumn] = useState<string | null>("name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const handleCloseImages = () => setShowImages(false);
  const handleCloseCollabDeleteConfirm = () =>
    setShowCollabDeleteConfirm(false);

  const handleShowImages = (item: any) => {
    setCollaboration(item.name);
    setShowImages(true);
    ApiService.getCollaborationImages(item.id).then((response: any) => {
      setImages(response.data.slides);
      setImagesOriginal(response.data.slidesOriginal);
    });
  };

  const downloadCollaboration = (id: string) => {
    try {
      var apiUrl = context.apiUrl + "api/";
      setIsLoading(true);
      ApiService.getDownloadCollaboration(id).then((response: any) => {
        setIsLoading(false);
        window.open(
          apiUrl + "collaboration/download/direct/" + response.data,
          "_blank"
        );
      });
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
      setIsLoading(false);
    }
  };

  const handleShowCollabDeleteConfirm = (item: any) => {
    setCollaborationId(item.id);
    setCollaboration(item.name);
    setShowCollabDeleteConfirm(true);
  };

  const handleDeleteCollab = (id: any) => {
    ApiService.deleteCollaboration(id).then((response: any) => {
      if (response.data.length === 0) {
        props.getItems();
        handleCloseCollabDeleteConfirm();
      } else {
        setIsValidDelete(false);
        setErrorsDelete(response.data);
      }
    });
  };

  const handleShowCollabEdit = (item: any) => {
    props.setItemEdit(item);
    props.setSelectedEdit(true);
  };

  const toggleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortItems = (items: any[]) => {
    if (!sortColumn) return items;

    return [...items].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const renderSortArrow = (column: string) => {
    if (sortColumn !== column) {
      return null;
    }

    if (sortDirection === "desc") {
      return <ArrowDown />;
    } else {
      return <ArrowUp />;
    }
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-background">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="row mb-3">
        <div className="col-md-12 text-right">
          <Button
            variant="primary-outline"
            onClick={() => props.setSelectedAdd(true)}
          >
            Start {auth()?.transCollab}
          </Button>
        </div>
      </div>
      <table className="table fs-0 mb-0 border-top border-200">
        <thead>
          <tr>
            <th className="mouse-pointer" onClick={() => toggleSort("name")}>
              Name&nbsp;{renderSortArrow("name")}
            </th>
            <th className="text-center">Slides</th>
            <th className="mouse-pointer" onClick={() => toggleSort("status")}>
              Status&nbsp;{renderSortArrow("status")}
            </th>
            <th className="text-center">Type</th>
            <th>Created/By</th>
            <th
              className="text-center mouse-pointer"
              onClick={() => toggleSort("tasksActive")}
            >
              Active Tasks&nbsp;{renderSortArrow("tasksActive")}
            </th>
            <th
              className="text-center mouse-pointer"
              onClick={() => toggleSort("tasksCompleted")}
            >
              Closed Tasks&nbsp;{renderSortArrow("tasksCompleted")}
            </th>
            <th
              className="text-center mouse-pointer"
              onClick={() => toggleSort("tasks")}
            >
              Total Tasks&nbsp;{renderSortArrow("tasks")}
            </th>
            <th className="text-center">Download</th>
            {auth()?.isTenantAdmin && (
              <>
                <th className="text-center">Template</th>
                <th className="text-center">Visible</th>
                <th className="text-center">Theme</th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortItems(props.items).map((item: any) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td className="text-center">
                {item.isOwner && (
                  <span
                    onClick={() => handleShowImages(item)}
                    className="mouse-pointer"
                  >
                    <Files />
                  </span>
                )}
                {!item.isOwner && (
                  <span title="You cannot view since you are not the owner of the item.">
                    -
                  </span>
                )}
              </td>
              <td>{item.status}</td>
              <td className="text-center">
                <img
                  src={PPTXIcon}
                  alt="PowerPoint"
                  title="PowerPoint"
                  width="25"
                ></img>
              </td>
              <td>
                <span
                  className="badge rounded-pill bg-secondary"
                  title={moment(item.created).format("YYYY-MMM-DD HH:mm")}
                >
                  {item.createdStringSimple}
                </span>
                &nbsp;
                <span
                  className="taskUser badge bg-primary me-1"
                  title={item.createdByUser.fullName}
                >
                  {item.createdByUser.initials}
                </span>
              </td>
              <td className="text-center">
                {item.isOwner && <>{item.tasksActive}</>}
                {!item.isOwner && <>-</>}
              </td>
              <td className="text-center">
                {item.isOwner && <>{item.tasksCompleted}</>}
                {!item.isOwner && <>-</>}
              </td>
              <td className="text-center">
                {item.isOwner && <>{item.tasks}</>}
                {!item.isOwner && <>-</>}
              </td>
              <td className="text-center">
                {item.isOwner && (
                  <span
                    onClick={() => downloadCollaboration(item.id)}
                    className="mouse-pointer"
                  >
                    <CloudDownload />
                  </span>
                )}
                {!item.isOwner && (
                  <span title="You cannot download since you are not the owner of the item.">
                    -
                  </span>
                )}
              </td>
              {auth()?.isTenantAdmin && (
                <>
                  <td className="text-center">{item.isTemplate}</td>
                  <td className="text-center">{item.isVisible}</td>
                  <td className="text-center">{item.isTheme}</td>
                </>
              )}
              <td className="text-end">
                {item.isOwner && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      size="sm"
                    >
                      <Sliders size={10} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {item.canEdit && (
                        <Dropdown.Item
                          onClick={() => handleShowCollabEdit(item)}
                          disabled={!item.canEdit}
                        >
                          Edit
                        </Dropdown.Item>
                      )}
                      {item.canDelete && (
                        <Dropdown.Item
                          disabled={!item.canDelete}
                          onClick={() => handleShowCollabDeleteConfirm(item)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        show={showImages}
        onHide={handleCloseImages}
        backdrop="static"
        keyboard={false}
        className="modal-xl"
      >
        <Modal.Header>
          <Modal.Title>
            {auth()?.transCollabs} - {collaboration}
          </Modal.Title>
          <Button variant="close" onClick={handleCloseImages}></Button>
        </Modal.Header>
        <Modal.Body>
          <SlidesDisplay
            originalSlides={imagesOriginal}
            updatedSlides={images}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleCloseImages}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCollabDeleteConfirm}
        onHide={handleCloseCollabDeleteConfirm}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{collaboration}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isValidDelete &&
            errorsDelete.map((error: Error, index) => (
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
                key={index}
              >
                {error.message}
              </div>
            ))}
          Are you sure you want to delete this {auth()?.transCollab}?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary-outline"
            onClick={handleCloseCollabDeleteConfirm}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteCollab(collaborationId)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { ListCollaboration };
