import React, { useState } from "react";
import ApiService from "../../services/ApiService";
import ValidationService from "../../services/ValidationService";
import Button from "react-bootstrap/Button";
import { Error, UserAdd } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";

function TaskAddUser(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const [isComplete, setIsComplete] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
  });

  const addUser = async () => {
    if (ValidationService.checkPassword(values.password).length > 0) {
      setIsValid(false);
      setErrors([
        {
          type: "password",
          message:
            "Password validation error - " +
            ValidationService.checkPassword(values.password),
          stackTrace: "",
        },
      ]);
    } else if (ValidationService.validateEmail(values.email).length > 0) {
      setIsValid(false);
      setErrors([
        {
          type: "email",
          message:
            "Email validation error - " +
            ValidationService.validateEmail(values.email),
          stackTrace: "",
        },
      ]);
    } else {
      try {
        const response = await ApiService.addUser(values);
        setErrors(response.data);
        if (response.data.length > 0) {
          setIsValid(false);
        } else {
          setIsComplete(true);
          values.email = "";
          values.password = "";
          values.firstname = "";
          values.lastname = "";
          props.getCollaborationUsers();
        }
        console.log(response.data);
      } catch (e: any) {
        setIsValid(false);
        console.log("Error -> " + e.toJSON().message);
      }
    }
  };

  const resetForm = () => {
    setIsValid(true);
    setIsComplete(false);
    setErrors([]);
    setValues({
      email: "",
      password: "",
      firstname: "",
      lastname: "",
    });
  };

  const hideUserAdd = () => {
    resetForm();
    props.setAddUser(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    console.log(values);
  };

  return (
    <div className="alert alert-secondary">
      <h5>Add User</h5>
      {!isValid &&
        errors.map((error: Error, index) => (
          <Alert key={index} variant="warning">
            {error.message}
          </Alert>
        ))}
      {isComplete && (
        <span>
          <Alert variant="info">
            The user has been added and is now available in the workflow users
            list below.
          </Alert>
          <div className="row mt-4">
            <div className="col-md-6">
              <Button variant="primary-outline" onClick={hideUserAdd}>
                Go Back
              </Button>
            </div>
            <div className="col-md-6 text-right">
              <Button variant="primary" onClick={resetForm}>
                Add Another
              </Button>
            </div>
          </div>
        </span>
      )}
      {!isComplete && (
        <div>
          <div className="mb-3">
            <label>Email address</label>
            <input
              className="form-control"
              name="email"
              value={values.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event)
              }
              placeholder="Enter email"
              type="email"
            />
            <label className="text-muted">
              We'll never share your email with anyone else.
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              className="form-control"
              name="password"
              value={values.password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event)
              }
              placeholder="Password"
              type="password"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">First Name</label>
            <input
              className="form-control"
              name="firstname"
              value={values.firstname}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event)
              }
              placeholder="Enter first name"
              type="text"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Last Name</label>
            <input
              className="form-control"
              name="lastname"
              value={values.lastname}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event)
              }
              placeholder="Enter last name"
              type="text"
            />
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <Button variant="primary-outline" onClick={hideUserAdd}>
                Go Back
              </Button>
            </div>
            <div className="col-md-6 text-right">
              <Button variant="primary" onClick={() => addUser()}>
                Add User
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { TaskAddUser };
