import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import Dropdown from "react-bootstrap/Dropdown";
import { Sliders } from "react-bootstrap-icons";
import { get } from "http";

function Tenants(props: any) {
  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    getTenants();
  }, []);

  const getTenants = async () => {
    try {
      const response = await ApiService.getDashboardTenants();
      setTenants(response.data);
    } catch (e) {
      console.log("Error");
    }
  };

  const handleButtonDeleteTenantDataClick = (id: any) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete tenant data?"
    );
    if (isConfirmed) {
      deleteTenantData(id);
    }
  };

  const handleButtonDeleteTenantClick = (id: any) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete tenant?"
    );
    if (isConfirmed) {
      deleteTenant(id);
    }
  };

  const deleteTenantData = async (id: any) => {
    try {
      const response = await ApiService.deleteTenantData(id);
      getTenants();
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const deleteTenant = async (id: any) => {
    try {
      const response = await ApiService.deleteTenant(id);
      getTenants();
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
    }
  };

  return (
    <>
      <div className="col-12 col-md-12 mt-3 mb-3">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="mb-1">Tenants Summary</h5>
              </div>
            </div>
            <div className="pb-0 pt-4">
              <table className="table fs-0 mb-0 border-top border-200">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Active</th>
                    <th>Users</th>
                    <th>Templates</th>
                    <th>Projects (active/total)</th>
                    <th>Tasks (active/total)</th>
                    <th>Activity Logs</th>
                    <th>Last Activity (UTC)</th>
                    <th className="text-right">Storage Used (mb)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tenants.map(
                    (item: any) => (
                      <tr key={item.id}>
                        <td>
                          {item.name}{" "}
                          {item.template ? (
                            <span> [Template]</span>
                          ) : (
                            <span></span>
                          )}
                        </td>
                        <td>
                          {item.active ? <span>Yes</span> : <span>No</span>}
                        </td>
                        <td>{item.users?.length}</td>
                        <td>{item.templates}</td>
                        <td>
                          {item.projectsActive}/{item.projectsTotal}
                        </td>
                        <td>
                          {item.tasksActive}/{item.tasksTotal}
                        </td>
                        <td>{item.activityLogs}</td>
                        <td>{item.activityLastString}</td>
                        <td className="text-right">{item.storageUsed}</td>
                        <td>
                          {!item.template && (
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                size="sm"
                              >
                                <Sliders size={10} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleButtonDeleteTenantDataClick(item.id)}
                                >
                                  Delete Tenant Data
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleButtonDeleteTenantClick(item.id)}
                                >
                                  Delete Tenant
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </td>
                      </tr>
                    ),
                    []
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Tenants };
