import React, { useState } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

function ResetPasswordRequest(props: any) {
  const [isReset, setIsReset] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);

  const onSubmit = async (values: any) => {
    try {
      const response = await ApiService.resetPasswordRequest(values);
      if (response.data.length > 0) {
        setIsValid(false);
        setErrors(response.data);
      } else {
        setIsValid(true);
        setIsReset(true);
      }      
    } catch (err) {
      setIsReset(false);
      console.log("Error: ", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    onSubmit,
  });

  return (
    <div className="container-fluid px-0">
      <div className="container">
        <div className="row flex-center py-5">
          <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
            <h3>Password Reset Request</h3>
            <span className="p-3"></span>
            {!isValid &&
              errors.map((error: Error, index) => (
                <Alert key={index} variant="warning">
                  {error.message}
                </Alert>
              ))}
            {!isReset && (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    placeholder="Enter email"
                    type="email"
                  />
                </Form.Group>
                <Button type="submit" className="btn btn-primary w-100 mb-3">
                  Request Reset Password
                </Button>
              </Form>
            )}
            {isReset && (
              <Alert variant="success">
                Please check your email for a password reset link. <br />
                If you did not receive it, please check your spam folder.
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { ResetPasswordRequest };
