import React, { useContext, useState } from "react";
import { UserContext, User } from "../../context/UserContext";
import { useSignIn } from "react-auth-kit";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

function Login(props: any) {
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const [isValid, setIsValid] = useState(true);

  const onSubmit = async (values: any) => {
    try {
      const response = await ApiService.login(values);
      const user: User = {
        id: response.data.user.id,
        name: response.data.user.fullName,
        email: response.data.user.email,
        isadmin: response.data.user.isAdmin,
        istenantadmin: response.data.user.isTenantAdmin,
        istwoFactor: response.data.user.isTwoFactor,
      };
      signIn({
        token: response.data,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState: {
          email: values.username,
          user: response.data.token,
          id: response.data.user.id,
          transCollab: response.data.user.transCollaboration,
          transCollabs: response.data.user.transCollaborations,
          transTask: response.data.user.transTask,
          transTasks: response.data.user.transTasks,
        },
      });
      console.log(response.data);
      Cookies.set("_webapp_client_jwt", response.data.token);
      setUser(user);
      setIsValid(true);
      const response2fa = await ApiService.isTwoFactor();
      if (response2fa.data === true) {
        navigate("/twofactor");
      } else {
        navigate("/");
      }
    } catch (err) {
      setIsValid(false);
      console.log("Error: ", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      timezoneoffset: new Date().getTimezoneOffset(),
    },
    onSubmit,
  });

  return (
    <div className="container-fluid px-0">
      <div className="container">
        <div className="row flex-center py-5">
          <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
            <h3>Sign In</h3>
            <span className="p-3"></span>
            {!isValid && (
              <Alert variant="danger">
                Sorry, we couldn't log you in. Either your username or password
                is incorrect, or your account may not yet be activated. Please
                try entering your details again. If you're attempting to log in
                for the first time, ensure that you have activated your account
                by clicking the activation link sent to your email.
              </Alert>
            )}
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  placeholder="Enter email"
                  type="email"
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  placeholder="Password"
                  type="password"
                />
              </Form.Group>
              <div className="row flex-between-center mb-3">
                <div className="col-auto">&nbsp;</div>
                <div className="col-auto">
                  <a className="fs--1" href="/resetpasswordrequest">
                    Forgot Password?
                  </a>
                </div>
              </div>
              <Button type="submit" className="btn btn-primary w-100 mb-3">
                Sign In
              </Button>
              <div className="text-center">
                <a className="fs--1" href="/signup">
                  Create an account
                </a>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Login };
