import axios from "axios";
import Cookies from "js-cookie";

const apiUrl = () => {
  return fetch("/config.json")
    .then((response) => response.json())
    .then((config) => config.apiUrl);
};

const configAuth = () => {
  return {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${Cookies.get("_webapp_client_jwt")}`,
    },
  };
};

const configAuthFiles = () => {
  return {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${Cookies.get("_webapp_client_jwt")}`,
    },
  };
};

const login = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/authentication/login", values);
  });
};

const logout = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/authentication/logout", configAuth());
  });
};

const signup = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/tenantuser", values);
  });
};

const resetPasswordRequest = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user/resetPasswordRequest", values);
  });
};

const resetPassword = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user/resetPassword", values);
  });
};

const activateAccount = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user/activateaccount", values);
  });
};

const validatetwofactor = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user/validatetwofactor", values, configAuth());
  });
};

const userInfo = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/authentication/userinfo", configAuth());
  });
};

const isTwoFactor = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/istwofactor", configAuth());
  });
};

const getUsers = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/users", configAuth());
  });
};

const addUser = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user", values, configAuth());
  });
};

const inviteUser = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user-invite", values, configAuth());
  });
};

const inviteUserRegister = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user-invite-register", values, configAuth());
  });
};

const deleteUser = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user-delete", values, configAuth());
  });
};

const toggleUserActive = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user-toggle-active", values, configAuth());
  });
};

const toggleUserTwoFactor = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user-toggle-twofactor", values, configAuth());
  });
};

const getDashboardTenants = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/dashboard/tenants", configAuth());
  });
};

const getDashboardTenant = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/dashboard/tenant", configAuth());
  });
};

const getDashboardTenantsActivity = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/dashboard/tenants-activity", configAuth());
  });
};

const getUserActivity = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user/activity", values, configAuth());
  });
};

const getCurrentUserActivity = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/activity", configAuth());
  });
};

const editUserGet = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/edit?id=" + id, configAuth());
  });
};

const editUser = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user/edit", values, configAuth());
  });
};

const changePassword = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/user/changepassword", values, configAuth());
  });
};

const getCollaborations = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/collaborations", configAuth());
  });
};

const addCollaboration = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/collaboration", values, configAuthFiles());
  });
};

const getCollaborationImages = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/collaboration/slides?id=" + id, configAuth());
  });
};

const getDownloadTask = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/task/download/" + id, configAuth());
  });
};

const getDownloadCollaboration = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/collaboration/download/" + id, configAuth());
  });
};

const getDownloadTemplate = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/templates/download/" + id, configAuth());
  });
};

const getTasks = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/tasks", configAuth());
  });
};

const getTask = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/task/" + id, configAuth());
  });
};

const getTaskAdd = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/task/add/" + id, configAuth());
  });
};

const editTask = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/task/edit", values, configAuth());
  });
};

const addTask = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/task", values, configAuth());
  });
};

const deleteTask = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/task/delete", values, configAuth());
  });
};

const addTaskFile = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/task/file", values, configAuthFiles());
  });
};

const workflowSubmit = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/task/workflow", values, configAuth());
  });
};

const deleteTenantData = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/tenant/removedata/" + id, configAuth());
  });
};

const deleteTenant = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/tenant/remove/" + id, configAuth());
  });
};

const getTenantSettings = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/tenant/settings/", configAuth());
  });
};

const saveTenantSettings = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/tenant/settings", values, configAuth());
  });
};

const getTaskHistory = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/task/history/" + id, configAuth());
  });
};

const taskUpload = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/task/upload", values, configAuthFiles());
  });
};

const addTaskHistory = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/task/history", values, configAuth());
  });
};

const deleteCollaboration = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.delete(url + "api/collaboration/delete/" + id, configAuth());
  });
};

const editCollaboration = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/collaboration/edit", values, configAuth());
  });
};

const getDownloadTaskHistoryFile = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(
      url + "api/task/history/file/download/" + id,
      configAuth()
    );
  });
};

const cancelTask = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/task/cancel/" + id, configAuth());
  });
};

const getUserTerms = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/terms", configAuth());
  });
};

const getUserPrivacy = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/privacy", configAuth());
  });
};

const setUserTerms = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/terms/set", configAuth());
  });
};

const setUserPrivacy = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/privacy/set", configAuth());
  });
};

const getTemplates = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/templates/all", configAuth());
  });
};

const deleteTemplate = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.delete(url + "api/templates/" + id, configAuth());
  });
};

const addTemplate = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/templates/add", values, configAuthFiles());
  });
};

const editTemplate = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/templates/edit", values, configAuth());
  });
};

const addTemplateAi = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/ai/generate-pptx", values, configAuthFiles());
  });
};

const getThemes = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/templates/themes", configAuth());
  });
};

const addCollaborationFromTemplate = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(
      url + "api/collaboration-template",
      values,
      configAuthFiles()
    );
  });
};

const addCollaborationFromTemplateQuick = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(
      url + "api/collaboration-template-quick",
      values,
      configAuthFiles()
    );
  });
};

const processPayment = (priceId: string, userId: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(
      url + "api/payments/create-checkout-session/" + priceId + "/" + userId,
      configAuth()
    );
  });
};

const getProducts = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/transactions/products", configAuth());
  });
};

const getTransactions = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/transactions/all", configAuth());
  });
};

const addTransaction = (token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/transactions/add/" + token, configAuth());
  });
};

const getTemplateBalance = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/transactions/template-balance", configAuth());
  });
};

const getSuggestions = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/ai/generate-suggestions", values, configAuthFiles());
  });
};

const ApiService = {
  login,
  logout,
  userInfo,
  signup,
  resetPasswordRequest,
  resetPassword,
  activateAccount,
  isTwoFactor,
  validatetwofactor,
  getUsers,
  addUser,
  inviteUser,
  inviteUserRegister,
  deleteUser,
  toggleUserActive,
  toggleUserTwoFactor,
  getDashboardTenants,
  getDashboardTenantsActivity,
  getUserActivity,
  getCurrentUserActivity,
  editUserGet,
  editUser,
  getCollaborations,
  addCollaboration,
  getCollaborationImages,
  getTasks,
  getTask,
  editTask,
  addTask,
  deleteTask,
  getTaskAdd,
  getDownloadTask,
  addTaskFile,
  workflowSubmit,
  deleteTenantData,
  getTenantSettings,
  saveTenantSettings,
  getTaskHistory,
  taskUpload,
  addTaskHistory,
  getDownloadCollaboration,
  deleteCollaboration,
  editCollaboration,
  getDownloadTaskHistoryFile,
  cancelTask,
  getUserTerms,
  getUserPrivacy,
  setUserTerms,
  setUserPrivacy,
  deleteTenant,
  apiUrl,
  changePassword,
  getDashboardTenant,
  getTemplates,
  deleteTemplate,
  addTemplate,
  editTemplate,
  addTemplateAi,
  getThemes,
  addCollaborationFromTemplate,
  getDownloadTemplate,
  processPayment,
  getProducts,
  getTransactions,
  addTransaction,
  getTemplateBalance,
  getSuggestions,
  addCollaborationFromTemplateQuick,
};

export default ApiService;
