import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { AddTask } from "./tasks-add";
import { EditTask } from "./tasks-edit";
import { ListTasks } from "./tasks-list";
import { UploadTask } from "./tasks-upload";
import { useAuthUser } from "react-auth-kit";

function Tasks(props: any) {
  const [items, setItems] = useState([]);
  const [itemsRefresh, setItemsRefresh] = useState(false);
  const [selectedAdd, setSelectedAdd] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(false);
  const [itemEdit, setItemEdit] = useState();
  const auth = useAuthUser();

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [itemsRefresh, setItemsRefresh]);

  const getItems = async () => {
    try {
      const response = await ApiService.getTasks();
      setItems(response.data);
      props.setDashboardTriggerTasks(response.data.length);
      if (response.data.length === 0) {
        setSelectedAdd(true);
      }
      console.log(response.data);
    } catch (e) {
      console.log("Error");
    }
  };

  return (
    <>
      <div className="col-12 col-md-12 mb-3">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                {!selectedAdd && !selectedEdit && (
                  <h5 className="mb-1">{auth()?.transTasks}</h5>
                )}
                {selectedAdd && (
                  <h5 className="mb-1">Add {auth()?.transTask}</h5>
                )}
                {selectedEdit && (
                  <h5 className="mb-1">Edit {auth()?.transTask}</h5>
                )}
              </div>
            </div>
            <div className="pb-0">
              {!selectedAdd && !selectedEdit && (
                <>
                  {/* <UploadTask getItems={getItems} /> */}
                  <ListTasks
                    items={items}
                    itemsRefresh={setItemsRefresh}
                    setSelectedAdd={setSelectedAdd}
                    setSelectedEdit={setSelectedEdit}
                    setItemEdit={setItemEdit}
                    getItems={getItems}
                  />
                </>
              )}
              {selectedEdit && (
                <EditTask
                  itemId={selectedEdit}
                  setSelectedItem={setSelectedEdit}
                  getItems={getItems}
                  itemEdit={itemEdit}
                />
              )}
              {selectedAdd && (
                <AddTask displayItemAdd={setSelectedAdd} getItems={getItems} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Tasks };
