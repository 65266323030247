import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Error, UserEdit } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";

function EditUser(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const [editUserRoles, setEditUserRoles] = useState([] as any[]);

  useEffect(() => {
    handleEditGet();
  }, []);

  const handleEditGet = async () => {
    try {
      const response = await ApiService.editUserGet(props.userId);
      setEditUserRoles(response.data.rolesAvailable);
      formik.values.firstname = response.data.firstName;
      formik.values.lastname = response.data.lastName;
      formik.values.roleid = response.data.roleId;
      formik.values.id = response.data.id;
      formik.values.email = response.data.email;
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const onSubmit = async (values: UserEdit) => {
    try {
      const response = await ApiService.editUser(values);
      setErrors(response.data);
      if (response.data.length > 0) {
        setIsValid(false);
      } else {
        props.getUsers();
        props.setSelectedUser(null);
      }
      console.log(response.data);
    } catch (e: any) {
      setIsValid(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const cancelSelectedUser = () => {
    props.setSelectedUser(null);
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      roleid: "",
      firstname: "",
      lastname: "",
      email: "",
    },
    onSubmit,
  });

  const handleRoleChange = (e: any) => {
    formik.values.roleid = e.target.value;
  };

  return (
    <div>
      {!isValid &&
        errors.map((error: Error, index) => (
          <Alert key={index} variant="warning">
            {error.message}
          </Alert>
        ))}
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicFirstName">
          <Form.Label>Username</Form.Label>
          <Form.Control
            name="firstname"
            value={formik.values.email}
            onChange={formik.handleChange}
            type="text"
            disabled={true}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            name="firstname"
            value={formik.values.firstname}
            onChange={formik.handleChange}
            placeholder="Enter first name"
            type="text"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            name="lastname"
            value={formik.values.lastname}
            onChange={formik.handleChange}
            placeholder="Enter last name"
            type="text"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Role</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={handleRoleChange}
          >
            {editUserRoles.map((role) => (
              <option
                key={role.id}
                value={role.id}
                selected={role.id === formik.values.roleid}
              >
                {role.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <div className="row mt-4">
          <div className="col-md-6">
            <Button variant="primary-outline" onClick={cancelSelectedUser}>
              Go Back
            </Button>
          </div>
          <div className="col-md-6 text-right">
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export { EditUser };
