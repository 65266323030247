/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Square, Check2Square } from "react-bootstrap-icons";
import { SlidesDisplaySimple } from "../common/slides-display-simple";
import { Files } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ThemeDisplaySimple(props: any) {
  const [images, setImages] = useState([]);
  const [showImages, setShowImages] = useState(false);

  const handleCloseImages = () => setShowImages(false);

  const handleShowImages = () => {
    setShowImages(true);
    setImages(props.theme.slides);
  };

  useEffect(() => {
    console.log(props.themeSelectedId);
    console.log(props.theme);
  }, [props.themeSelected]);

  return (
    <>
      <div
        className="col-lg-4 col-md-4 col-xl-3 col-sm-4 col-xs-6"
        onClick={() => props.onSelectTheme(props.theme)}
      >
        <div
          className={
            props.themeSelectedId === props.theme.id
              ? "card shadow-smb order theme-selected"
              : "card shadow-smb"
          }
        >
          <img
            src={props.theme.slides[0]}
            className="bd-placeholder-img card-img-top"
            alt="Thumbnail"
            width="100%"
          />
          <div className="card-body">
            <p className="card-text">
              <div className="row">
                <div className="col-8">
                  {props.themeSelectedId === props.theme.id && (
                    <>
                      <span className="theme-selected-text">
                        <Check2Square /> {props.theme.name}
                      </span>
                    </>
                  )}
                  {props.themeSelectedId !== props.theme.id && (
                    <>
                      <Square /> {props.theme.name}
                    </>
                  )}
                </div>
                <div className="col-4 text-end">
                  <span
                    className="badge bg-primary"
                    title="Complexity score - values are L1 to L3, L3 being the most complex."
                  >
                    L{props.theme.complexity}
                  </span>{" "}
                  <span
                    className="mouse-pointer"
                    title="Click to view slides"
                    onClick={() => handleShowImages()}
                  >
                    <Files />
                  </span>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
      <Modal
        show={showImages}
        onHide={handleCloseImages}
        backdrop="static"
        keyboard={false}
        className="modal-xl"
      >
        <Modal.Header>
          <Modal.Title>{props.theme.name}</Modal.Title>
          <Button variant="close" onClick={handleCloseImages}></Button>
        </Modal.Header>
        <Modal.Body>
          <SlidesDisplaySimple slides={images} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleCloseImages}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { ThemeDisplaySimple };
