import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import moment from "moment";
import { PaymentsWidget } from "./payments_widget";

function Payments() {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const [user, setUser] = useState();
  const [items, setItems] = useState([] as any);
  const location = useLocation();

  const getItems = async () => {
    try {
      const response = await ApiService.getTransactions();
      setItems(response.data);
    } catch (e) {
      console.log("Error");
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const item = queryParams.get("action");
    if (item) {
      addTransaction(item);
    }
  }, [location]);

  useEffect(() => {
    getItems();
  }, []);

  const addTransaction = async (token: string) => {
    try {
      const response = await ApiService.addTransaction(token);
      console.log(response);
      navigate("/payments");
    } catch (e) {
      console.log("Error");
    }
  };

  return (
    <>
      <PaymentsWidget />
      <div className="col-12 col-md-12">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="mb-1">Transactions</h5>
            </div>
            <div className="row mt-4"></div>
            <div className="pb-0">
              {items.map((item: any) => (
                <div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <h6 className="mb-1">{item.type}</h6>
                    <small>
                      {item.description}
                      <br />
                      Credit: {item.templates}
                    </small>
                  </div>
                  <p className="mb-1">
                    <span
                      className="badge rounded-pill bg-secondary mouse-pointer"
                      title={moment(item.created).format("YYYY-MMM-DD HH:mm")}
                    >
                      {item.createdString}
                    </span>{" "}
                  </p>
                </div>
              ))}
              {items.length === 0 && (
                <div className="text-center">
                  <p>No transactions found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Payments };
