import React, { useState } from "react";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import ValidationService from "../../services/ValidationService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Error, UserSignup } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from "react-bootstrap/Spinner";

function Signup(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const [isComplete, setIsComplete] = useState(false);
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [acPrivacy, setAcPrivacy] = useState(false);
  const [acTerms, setAcTerms] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAcknowledgePrivacy = () => {
    setShowModalPrivacy(false);
    setAcPrivacy(true);
  };

  const handleAcknowledgeTerms = () => {
    setShowModalTerms(false);
    setAcTerms(true);
  };

  const handleAcknowledgePrivacyClose = () => {
    setShowModalPrivacy(false);
  };

  const handleAcknowledgeTermsClose = () => {
    setShowModalTerms(false);
  };

  const onSubmit = async (values: UserSignup) => {
    if (!recaptchaToken) {
      setIsValid(false);
      setErrors([
        {
          type: "email",
          message: "Please check the reCAPTCHA box.",
          stackTrace: "",
        },
      ]);
      return;
    }
    if (ValidationService.checkPassword(values.password).length > 0) {
      setIsValid(false);
      setErrors([
        {
          type: "password",
          message:
            "Password validation error - " +
            ValidationService.checkPassword(values.password),
          stackTrace: "",
        },
      ]);
    } else if (ValidationService.validateEmail(values.email).length > 0) {
      setIsValid(false);
      setErrors([
        {
          type: "email",
          message:
            "Email validation error - " +
            ValidationService.validateEmail(values.email),
          stackTrace: "",
        },
      ]);
    } else {
      try {
        setIsLoading(true);
        const response = await ApiService.signup(values);
        setErrors(response.data);
        if (response.data.length > 0) {
          setIsValid(false);
        } else {
          setIsComplete(true);
        }
        setIsLoading(false);
        console.log(response.data);
      } catch (e: any) {
        setIsValid(false);
        setIsLoading(false);
        console.log("Error -> " + e);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      companyname: "",
    },
    onSubmit,
  });

  const onRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  return (
    <>
    {isLoading && (
        <div className="spinner-background">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="container-fluid px-0">
        <div className="container">
          <div className="row flex-center py-5">
            <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
              <h3>Sign Up</h3>
              <span className="p-3"></span>
              {!isValid &&
                errors.map((error: Error, index) => (
                  <Alert key={index} variant="warning">
                    {error.message}
                  </Alert>
                ))}
              {!isComplete && (
                <Form onSubmit={formik.handleSubmit}>                  
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="Enter email"
                      type="email"
                    />
                    <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      placeholder="Password"
                      type="password"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      name="firstname"
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                      placeholder="Enter first name"
                      type="text"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      name="lastname"
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      placeholder="Enter last name"
                      type="text"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCompanyName">
                    <Form.Label>Company/Team (optional)</Form.Label>
                    <Form.Control
                      name="companyname"
                      value={formik.values.companyname}
                      onChange={formik.handleChange}
                      placeholder="Enter company/team name (optional)"
                      type="text"
                    />
                  </Form.Group>                  
                  <div className="row flex-between-center mb-3 text-center"></div>
                  {(!acPrivacy || !acTerms) && (
                    <Alert variant="primary" className="text-center">
                      Please acknowledge:
                      {!acPrivacy && (
                        <div className="row">
                          <a
                            href="#"
                            onClick={() => setShowModalPrivacy(true)}
                            className="mouse-pointer"
                          >
                            Privacy Policy
                          </a>
                        </div>
                      )}
                      {!acTerms && (
                        <div className="row">
                          <a
                            href="#"
                            onClick={() => setShowModalTerms(true)}
                            className="mouse-pointer"
                          >
                            Terms and Conditions
                          </a>
                        </div>
                      )}
                    </Alert>
                  )}                  
                  <ReCAPTCHA
                    sitekey="6Lev2Q0pAAAAAIgKAeG2NBkmJcXGTzW38lJ0s3Mg"
                    onChange={onRecaptchaChange}
                  />
                  <div className="row flex-between-center mb-3 text-center"></div>
                  <Button
                    type="submit"
                    className="btn btn-primary w-100 mb-3"
                    disabled={acPrivacy && acTerms ? false : true}
                  >
                    Sign Up
                  </Button>
                </Form>
              )}
              {isComplete && (
                <Alert variant="success">
                  Your account has been created.
                  <br />
                  Please check your email to activate your account.
                </Alert>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalPrivacy}
        onHide={handleAcknowledgePrivacyClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <embed
            src="https://www.slidecollab.com/documents/privacy_policy.pdf"
            width="100%"
            height="500px"
            type="application/pdf"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAcknowledgePrivacy}>
            Acknowledge Privacy Policy
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalTerms}
        onHide={handleAcknowledgeTermsClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <embed
            src="https://www.slidecollab.com/documents/terms_and_conditions.pdf"
            width="100%"
            height="500px"
            type="application/pdf"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAcknowledgeTerms}>
            Acknowledge Terms and Conditions
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { Signup };
