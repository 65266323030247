import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import Alert from "react-bootstrap/Alert";

function ActivateAccount(props: any) {
  const [isActivate, setIsActive] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  useEffect(() => {
    var values = {
      token: token,
    };
    ApiService.activateAccount(values)
      .then((response) => {
        setIsActive(true);
      })
      .catch((err) => {
        setIsActive(false);
        console.log("Error: ", err);
      });
  });

  return (
    <div className="container-fluid px-0">
      <div className="container">
        <div className="row flex-center py-5">
          <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
            <h3>Account Activation</h3>
            <span className="p-3"></span>
            {isActivate && (
              <Alert variant="success">
                Your account has been activated. Please login to continue.
                <br />
                <span className="pt-5"></span>
                <a href="/login">Login</a>
              </Alert>
            )}
            {!isActivate && (
              <Alert variant="warning">
                Your account has not been activated. Please contact Support.
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { ActivateAccount };
