import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import { Error } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";
import { Accordion } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAuthUser } from "react-auth-kit";
import Spinner from "react-bootstrap/Spinner";
import { RotatingSquare } from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import { SlidesDisplaySimple } from "../common/slides-display-simple";
import { ThemeDisplaySimple } from "./theme-display-simple";
import { TopicSuggestions } from "./topic-suggestions";

type FormData = {
  query: string;
  themeId: string;
  detailed: boolean;
};

function AddTemplateAi(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const { register, handleSubmit, setValue } = useForm<FormData>();
  const [uploadStatus, setUploadStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuthUser();
  const [themes, setThemes] = useState([]);
  const [slides, setSlides] = useState([] as any);
  const [images, setImages] = useState([]);
  const [showImages, setShowImages] = useState(false);
  const [theme, setTheme] = useState("");
  const [themeId, setThemeId] = useState("");
  const [processingError, setProcessingError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const hideItemAdd = () => {
    props.hideItemAdd();
  };

  const handleCloseImages = () => setShowImages(false);

  const handleShowImages = () => {
    setShowImages(true);
    setImages(slides);
  };

  const hideItemAddAi = () => {
    props.getItems();
    props.setSelectedAddAi(false);
  };

  const themeSelected = (e: any) => {
    themes.map((theme: { id: string; name: string; slides: [] }) => {
      if (theme.id === e.id) {
        setSlides(theme.slides);
        setTheme(theme.name);
        setThemeId(theme.id);
        setValue("themeId", theme.id);
      }
    });
  };

  const populateTopic = (e: string) => {
    setValue("query", e);
  };

  useEffect(() => {
    getThemes();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    console.log("Detailed: " + event.target.checked);
    setValue("detailed", event.target.checked);
  };

  const getThemes = async () => {
    try {
      setIsLoading(true);
      const response = await ApiService.getThemes();
      setThemes(response.data);
      setValue("themeId", response.data[0].id);
      setTheme(response.data[0].name);
      setSlides(response.data[0].slides);
      setThemeId(response.data[0].id);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const onSubmit = async (data: FormData) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("query", data.query);
      formData.append("themeId", data.themeId);
      formData.append("detailed", isChecked.toString());
      const response = await ApiService.addTemplateAi(formData);
      setErrors(response.data);
      setIsLoading(false);
      if (response.data.length > 0) {
        setIsValid(false);
      } else {
        console.log("File Uploaded!");
        props.getItems();
        hideItemAddAi();
      }
    } catch (e: any) {
      setIsValid(false);
      setIsLoading(false);
      //setProcessingError(true);
      onSubmitTwo(data);
      console.log("Error -> " + e);
    }
  };

  const onSubmitTwo = async (data: FormData) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("query", data.query);
      formData.append("themeId", data.themeId);
      formData.append("detailed", isChecked.toString());
      const response = await ApiService.addTemplateAi(formData);
      setErrors(response.data);
      setIsLoading(false);
      if (response.data.length > 0) {
        setIsValid(false);
      } else {
        console.log("File Uploaded!");
        props.getItems();
        hideItemAddAi();
      }
    } catch (e: any) {
      setIsValid(false);
      setIsLoading(false);
      setProcessingError(true);
      console.log("Error -> " + e);
    }
  };

  return (
    <>
      {!isValid &&
        errors.map((error: Error, index) => (
          <Alert key={index} variant="warning">
            {error.message}
          </Alert>
        ))}
      {processingError && (
        <Alert variant="warning">
          There was an error processing your request. Please try again.
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        {isLoading && (
          <>
            <div className="spinner-background">
              <RotatingSquare
                visible={true}
                height="100"
                width="100"
                color="#fc04a3"
                ariaLabel="rotating-square-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          </>
        )}
        <div className="mb-4">
          <label htmlFor="name" className="form-label text-s10">
            Presentation Topic:
          </label>
          <br /> <TopicSuggestions populateTopic={populateTopic} />
          <input
            id="name"
            type="text"
            {...register("query")}
            className="form-control"
          />
          <div className="mt-1">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleChange}
              className="form-check-input"
            />{" "}
            &nbsp; Generate Detailed Content - includes more slides (15+),
            graphs and timelines - it also takes longer to generate
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="themeId" className="form-label text-s10">
            Select a Design Theme:
          </label>
          <div className="row">
            <div className="container">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                {themes.map((themeItem, index) => (
                  <ThemeDisplaySimple
                    theme={themeItem}
                    themeSelectedId={themeId}
                    onSelectTheme={themeSelected}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* &nbsp;
          <span className="mouse-pointer" onClick={() => handleShowImages()}>
            <Files /> View Slides
          </span> */}
        </div>
        {isLoading && (
          <div className="mb-3 mt-3">
            <div className="alert alert-info" role="alert">
              Please note that it may take a few minutes to generate a new
              presentation. Please be patient.
            </div>
          </div>
        )}
        <div className="alert alert-info" role="alert">
          Disclaimer - presentation generated by SlideCollab have been created
          using artificial intelligence. Please be aware that the AI may
          sometimes produce inaccurate, misleading, offensive, or biased
          content. It is not designed to offer advice.
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <Button
              variant="primary-outline"
              onClick={hideItemAddAi}
              hidden={props.firstTemplate}
            >
              Go Back
            </Button>
          </div>
          <div className="col-md-6 text-right">
            <Button variant="primary" type="submit" disabled={isLoading}>
              Generate
            </Button>
          </div>
        </div>
      </form>
      <Modal
        show={showImages}
        onHide={handleCloseImages}
        backdrop="static"
        keyboard={false}
        className="modal-xl"
      >
        <Modal.Header>
          <Modal.Title>Theme - {theme}</Modal.Title>
          <Button variant="close" onClick={handleCloseImages}></Button>
        </Modal.Header>
        <Modal.Body>
          <SlidesDisplaySimple slides={images} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleCloseImages}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { AddTemplateAi };
