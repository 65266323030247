import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import { useAuthUser } from "react-auth-kit";

function Tenant(props: any) {
  const [users, setUsers] = useState(0);
  const [usersLimit, setUsersLimit] = useState(0);
  const [storage, setStorage] = useState("");
  const [storageLimit, setStorageLimit] = useState("");
  const [collaborations, setCollaboratons] = useState(0);
  const [templates, setTemplates] = useState(0);
  const [tasks, setTasks] = useState(0);
  const auth = useAuthUser();

  useEffect(() => {
    getTenantInfo();
  }, [props.dashboardTriggerCollabs]);

  useEffect(() => {
    getTenantInfo();
  }, [props.dashboardTriggerTasks]);

  const getTenantInfo = async () => {
    try {
      const response = await ApiService.getDashboardTenant();
      setUsers(response.data.users.length);
      setUsersLimit(response.data.usersLimit);
      setStorage(response.data.storageUsed);
      setStorageLimit(response.data.storageLimit);
      setCollaboratons(response.data.projectsTotal);
      setTasks(response.data.tasksTotal);
      setTemplates(response.data.templates);
    } catch (e) {
      console.log("Error");
    }
  };

  return (
    <div className="row">
      <div className="col-md-3 col-sm-12 mt-3 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 text-left mt-1">Users</div>
              <div className="col-md-6 text-right">
                <h5 className="card-title">
                  {users}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-12 mt-3 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 text-left mt-1">Templates</div>
              <div className="col-md-6 text-right">
                <h5 className="card-title">
                  {templates}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-12 mt-3 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-8 text-left mt-1">
                {auth()?.transCollabs}
              </div>
              <div className="col-md-4 text-right">
                <h5 className="card-title">
                  <h5 className="card-title">{collaborations}</h5>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-12 mt-3 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-8 text-left mt-1">
                {auth()?.transTasks}
              </div>
              <div className="col-md-4 text-right">
                <h5 className="card-title">
                  <h5 className="card-title">{tasks}</h5>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Tenant };
