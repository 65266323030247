import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { useAuthUser } from "react-auth-kit";
import { loadStripe, Stripe, StripeError } from "@stripe/stripe-js";
import { get } from "http";

function PaymentsWidget(props: any) {
  const auth = useAuthUser();
  const [stripeKey, setStripeKey] = useState("");
  const [items, setItems] = useState([] as any);
  const [templatesAvailable, setTemplatesAvailable] = useState(0);
  const [templatesUsed, setTemplatesUsed] = useState(0);
  const [templatesTotal, setTemplatesTotal] = useState(0);

  const stripePublicKey = () => {
    return fetch("/config.json")
      .then((response) => response.json())
      .then((config) => config.stripePublicKey);
  };

  useEffect(() => {
    stripePublicKey().then((stripePublicKey) => {
      setStripeKey(stripePublicKey);
      //console.log(stripePublicKey);
    });
    getItems();
    getBalance();
  }, []);

  useEffect(() => {   
    getBalance();
  }, [props.items]);

  const getItems = async () => {
    try {
      const response = await ApiService.getProducts();
      setItems(response.data);
    } catch (e) {
      console.log("Error");
    }
  };

  const getBalance = async () => {
    try {
      const response = await ApiService.getTemplateBalance();
      setTemplatesAvailable(response.data.templatesAvailable);
      setTemplatesUsed(response.data.templates);
      setTemplatesTotal(response.data.templatesTotal);
    } catch (e) {
      console.log("Error");
    }
  };

  const handleBuyPack = async (priceId: string) => {
    const stripePromise = loadStripe(stripeKey);
    try {
      ApiService.processPayment(priceId, auth()?.id).then(
        async (response: any) => {
          const session = response.data;
          const stripe = await stripePromise;
          if (stripe) {
            stripe.redirectToCheckout({
              sessionId: session.sessionId,
            });
          }
        }
      );
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="card h-100 mb-3">
        <div className="card-body">
          <div className="pb-0">
            <div className="row">
              <div className="col-md-6 text-left">
                Templates Available{" "}
                <span className="badge rounded-pill bg-light text-dark badge-large">
                  {" "}
                  {templatesAvailable}{" "}
                </span>
              </div>
              <div className="col-md-6 text-right">
                {items.map((item: any) => (
                  <>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleBuyPack(item.priceId)}
                      key={item.priceId}
                    >
                      Buy {item.name}
                    </button>
                    &nbsp;
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { PaymentsWidget };
