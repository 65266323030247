import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { useNavigate } from "react-router-dom";
import { AddCollaboration } from "./collaborations-add";
import { EditCollaboration } from "./collaborations-edit";
import { ListCollaboration } from "./collaborations-list";
import { useAuthUser } from "react-auth-kit";

function Collaborations(props: any) {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [itemEdit, setItemEdit] = useState();
  const [itemsRefresh, setItemsRefresh] = useState(false);
  const [selectedAdd, setSelectedAdd] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(false);
  const [firstCollab, setFirstCollab] = useState(false);
  const auth = useAuthUser();

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [itemsRefresh, setItemsRefresh]);

  const getItems = async () => {
    try {
      const response = await ApiService.getCollaborations();
      setItems(response.data);
      props.setDashboardTriggerCollabs(response.data.length);
      if (response.data.length === 0) {
        setSelectedAdd(true);
        setFirstCollab(true);
        props.setTasksFlag(false);
      } else {
        props.setTasksFlag(true);
      }
    } catch (e) {
      console.log("Error");
    }
  };

  return (
    <>
      <div className="col-12 col-md-12 mb-3">
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                {!selectedAdd && !selectedEdit && (
                  <h5 className="mb-1">{auth()?.transCollabs}</h5>
                )}
                {selectedAdd && (
                  <h5 className="mb-1">Start {auth()?.transCollab}</h5>
                )}
                {selectedEdit && (
                  <h5 className="mb-1">Edit {auth()?.transCollab}</h5>
                )}
              </div>
            </div>
            <div className="pb-0">
              {!selectedAdd && !selectedEdit && (
                <ListCollaboration
                  items={items}
                  itemsRefresh={setItemsRefresh}
                  setSelectedAdd={setSelectedAdd}
                  setSelectedEdit={setSelectedEdit}
                  getItems={getItems}
                  setItemEdit={setItemEdit}
                />
              )}
              {selectedEdit && (
                <EditCollaboration
                  itemId={selectedEdit}
                  setSelectedItem={setSelectedEdit}
                  getItems={getItems}
                  itemEdit={itemEdit}
                />
              )}
              {selectedAdd && (
                <AddCollaboration
                  displayItemAdd={setSelectedAdd}
                  getItems={getItems}
                  firstCollab={firstCollab}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Collaborations };
