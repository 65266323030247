/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import { Error, TaskAdd } from "../../context/UserContext";
import Alert from "react-bootstrap/Alert";
import { set, useForm } from "react-hook-form";
import { useAuthUser } from "react-auth-kit";
import Spinner from "react-bootstrap/Spinner";
import { TaskAddUser } from "./tasks-add-user";
import { PersonPlus } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";

function AddTask(props: any) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const { handleSubmit } = useForm<FormData>();
  const [collaborations, setCollaborations] = useState([]);
  const [collaboration, setCollaboration] = useState("");
  const [collaborationId, setCollaborationId] = useState("");
  const [collaborationSlides, setCollaborationSlides] = useState([]);
  const [selectedSlides, setSelectedSlides] = useState<string[]>([]);
  const [selectedSlidesIndex, setSelectedSlidesIndex] = useState<string[]>([]);
  const [workflows, setWorkflows] = useState<any[]>([]);
  const [workflow, setWorkflow] = useState();
  const [workflowSteps, setWorkflowSteps] = useState<any[]>([]);
  const [workflowUsers, setWorkflowUsers] = useState<any[]>([]);
  const [slidesize, setSlidesize] = useState("col-md-4");
  const [workflowUsersSelected, setWorkflowUsersSelected] = useState<any[]>([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<TaskAdd>({
    name: "",
    description: "",
    collaboration: "",
    workflow: "",
    slides: [],
    users: [],
  });
  const [addUser, setAddUser] = useState(false);
  const [isConsecutive, setIsConsecutive] = useState(false);
  const location = useLocation();

  const auth = useAuthUser();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const item = queryParams.get("c");
    if (item) {
      getCollaborationSlides(item);
      setCollaboration(item);
      setSelectedSlidesIndex([]);
    }
    getCollaborations();
  }, []);

  useEffect(() => {
    checkConsecutive();
  }, [selectedSlides]);

  const getCollaborations = async () => {
    try {
      setIsLoading(true);
      const response = await ApiService.getCollaborations();
      setCollaborations(response.data);
      setIsLoading(false);
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
      setIsLoading(false);
    }
  };

  const getCollaborationSlides = async (id: any) => {
    setIsLoading(true);
    try {
      const response = await ApiService.getTaskAdd(id);
      setCollaborationId(id);
      setCollaborationSlides(response.data.collaboration.slides);
      setWorkflows(response.data.workflows);
      setWorkflowUsers(response.data.users);
      setWorkflowSteps(response.data.workflows[0].statuses);
      setWorkflow(response.data.workflows[0].id);
      console.log(response.data.collaboration.slides);
      setIsLoading(false);
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
      setIsLoading(false);
    }
  };

  const getCollaborationUsers = async () => {
    setIsLoading(true);
    try {
      const response = await ApiService.getTaskAdd(collaborationId);
      setWorkflowUsers(response.data.users);
      setIsLoading(false);
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
      setIsLoading(false);
    }
  };

  const hideItemAdd = () => {
    props.getItems();
    props.displayItemAdd(false);
  };

  const onSubmit = async (data: any) => {
    if (name === "") {
      setIsValid(false);
      setErrors([
        {
          type: "validation",
          message: "A name is required",
          stackTrace: "",
        },
      ]);
    } else if (selectedSlides && selectedSlides.length === 0) {
      setIsValid(false);
      setErrors([
        {
          type: "validation",
          message: "Please select at least one slide",
          stackTrace: "",
        },
      ]);
    } else if (workflowUsersSelected && workflowUsersSelected.length === 0) {
      setIsValid(false);
      setErrors([
        {
          type: "validation",
          message: "Please select at least one user",
          stackTrace: "",
        },
      ]);
    } else {
      try {
        setIsLoading(true);
        let tmpFormData = formData;
        tmpFormData.name = name;
        tmpFormData.description = description;
        tmpFormData.collaboration = collaboration ? collaboration : "";
        tmpFormData.slides = selectedSlides ? selectedSlides : [];
        tmpFormData.users = workflowUsersSelected ? workflowUsersSelected : [];
        tmpFormData.workflow = workflow ? workflow : "";
        setFormData(tmpFormData);
        const response = await ApiService.addTask(tmpFormData);
        setErrors(response.data);
        if (response.data.length > 0) {
          setIsValid(false);
        } else {
          console.log("task added!");
          props.getItems();
          hideItemAdd();
        }
        setIsLoading(false);
      } catch (e: any) {
        setIsValid(false);
        console.log("Error -> " + e.toJSON().message);
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event: any) => {
    getCollaborationSlides(event.target.value);
    setCollaboration(event.target.value);
    setSelectedSlidesIndex([]);
  };

  const handleClick = (id: any, index: any) => {
    if (selectedSlides.includes(id)) {
      setSelectedSlides(selectedSlides.filter((i) => i !== id));
    } else {
      setSelectedSlides([...selectedSlides, id]);
    }
    if (selectedSlidesIndex.includes(index)) {
      setSelectedSlidesIndex(selectedSlidesIndex.filter((i) => i !== index));
    } else {
      setSelectedSlidesIndex([...selectedSlidesIndex, index]);
    }
  };

  const handleChangeWorkflow = (event: any) => {
    for (const key in workflows) {
      if (workflows[key].id === event.target.value) {
        setWorkflow(event.target.value);
        setWorkflowSteps(workflows[key].statuses);
        break;
      }
    }
    setWorkflowUsersSelected([]);
  };

  const handleChangeWorkflowUsers = (event: any, statusId: any) => {
    const selectedUsers: string[] = [];
    const workflowUsers: string[] = workflowUsersSelected;
    const options = event.target.options;
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedUsers.push(statusId + "|" + options[i].value);
      }
    }
    for (let i = 0; i < workflowUsers.length; i++) {
      if (workflowUsers[i].indexOf(statusId) > -1) {
        workflowUsers.splice(i, 1);
      }
    }
    for (let i = 0; i < workflowUsers.length; i++) {
      if (workflowUsers[i].indexOf(statusId) > -1) {
        workflowUsers.splice(i, 1);
      }
    }
    for (let i = 0; i < selectedUsers.length; i++) {
      if (workflowUsers.indexOf(selectedUsers[i]) === -1) {
        workflowUsers.push(selectedUsers[i]);
      }
    }
    setWorkflowUsersSelected(workflowUsers);
  };

  const checkConsecutive = (): void => {
    const slidesIntegers: number[] = selectedSlidesIndex.map(Number).sort((a, b) => a - b);
    let consecutive = true;
    for (let i = 1; i < slidesIntegers.length; i++) {
      if (slidesIntegers[i] !== slidesIntegers[i - 1] + 1) {
        consecutive = false;
        break;
      }
    }
    setIsConsecutive(!consecutive);
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-background">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                {auth()?.transCollabs}:
              </label>
              <select
                title={auth()?.transCollabs}
                id="collaboration"
                className="form-control"
                value={collaboration}
                onChange={handleChange}
              >
                <option value="">Select a {auth()?.transCollab} ...</option>
                {collaborations.map((collaboration: any) => (
                  <option key={collaboration.id} value={collaboration.id}>
                    {collaboration.name} ({collaboration.slides.length} slides)
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {collaboration && (
          <div className="row">
            <div className="col-md-8">
              <div className="mb-3">
                <div className="row">
                  {collaborationSlides.length > 0 && (
                    <div className=" mb-3">
                      <a
                        href="#"
                        className={slidesize === "col-md-2" ? " fw-bold" : ""}
                        onClick={() => setSlidesize("col-md-2")}
                      >
                        Small
                      </a>{" "}
                      -{" "}
                      <a
                        href="#"
                        className={slidesize === "col-md-4" ? " fw-bold" : ""}
                        onClick={() => setSlidesize("col-md-4")}
                      >
                        Medium
                      </a>{" "}
                      -{" "}
                      <a
                        href="#"
                        className={slidesize === "col-md-6" ? " fw-bold" : ""}
                        onClick={() => setSlidesize("col-md-6")}
                      >
                        Large
                      </a>{" "}
                      -{" "}
                      <a
                        href="#"
                        className={slidesize === "col-md-12" ? " fw-bold" : ""}
                        onClick={() => setSlidesize("col-md-12")}
                      >
                        XLarge
                      </a>
                    </div>
                  )}

                  {collaborationSlides.length === 0 && (
                    <span className="form-label">
                      Please select an existing {auth()?.transCollab} from above
                    </span>
                  )}
                  {collaborationSlides &&
                    collaborationSlides.map((item: any, index) => (
                      <div key={item.id} className={slidesize}>
                        {item.isAssigned && (
                          <div className="row">
                            <div className="col-md-1 slideText">
                              {index + 1} -{" "}
                              <em>Assigned [{item.isAssignedTaskName}]</em>
                            </div>
                            <div className="col-md-11">
                              <img
                                src={item.filePath}
                                alt="Thumbnail"
                                className={"slideImage mb-1 slideAssigned"}
                                title="This slide is already assigned to a task"
                                style={{ opacity: item.isAssigned ? 0.2 : 1 }}
                              />
                            </div>
                          </div>
                        )}
                        {!item.isAssigned && (
                          <div className="row">
                            <div className="col-md-1 slideText">
                              {index + 1}
                            </div>
                            <div className="col-md-11">
                              <img
                                src={item.filePath}
                                alt="Thumbnail"
                                onClick={() => handleClick(item.id, index + 1)}
                                className={
                                  selectedSlides.includes(item.id)
                                    ? "slideImage mb-1 slideSelected"
                                    : "slideImage mb-1"
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Selected Slides:
                </label>
                <input
                  title="Selected Slides"
                  id="slidesSelected"
                  type="text"
                  className="form-control"
                  disabled
                  value={selectedSlidesIndex.sort((a: any, b: any) => a - b)}
                />
                {isConsecutive && (
                  <div className="alert alert-primary mt-2">
                    <em>If the slides selected are not consecutive the application will create a {auth()?.transTask} for each set of consecutive slides.</em>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name:
                </label>
                <input
                  id="name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description:
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Workflow:
                </label>
                <br />
                <select
                  title="Workflow"
                  name="workflow"
                  className="form-control"
                  onChange={handleChangeWorkflow}
                >
                  {workflows &&
                    workflows.map((item: any) => (
                      <option key={item.id} id={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-3">
                {!addUser && (
                  <>
                    {" "}
                    <span
                      className="mouse-pointer"
                      title="Add Users"
                      onClick={() => setAddUser(!addUser)}
                    >
                      <PersonPlus size={25} />
                    </span>
                  </>
                )}
                {addUser && (
                  <TaskAddUser
                    getCollaborationUsers={getCollaborationUsers}
                    setAddUser={setAddUser}
                  />
                )}
              </div>

              {workflowSteps &&
                workflowSteps
                  .filter(
                    (workflowItem) =>
                      workflowItem.isTypeApprove || workflowItem.isTypeSubmit
                  )
                  .map((item: any) => (
                    <div className="mb-3" key={item.id}>
                      <label htmlFor="name" className="form-label">
                        {item.name}
                      </label>
                      <br />
                      <select
                        title="Workflow"
                        name="workflow"
                        className="form-control"
                        onChange={(event) =>
                          handleChangeWorkflowUsers(event, item.id)
                        }
                        multiple
                        size={
                          workflowUsers.length > 10 ? 10 : workflowUsers.length
                        }
                      >
                        {workflowUsers &&
                          workflowUsers.map((item: any) => (
                            <option key={item.id} id={item.id} value={item.id}>
                              {item.fullName}
                            </option>
                          ))}
                      </select>
                    </div>
                  ))}
              <div className="mb-3">
                {!isValid &&
                  errors.map((error: Error, index) => (
                    <Alert key={index} variant="warning">
                      {error.message}
                    </Alert>
                  ))}
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <Button variant="primary-outline" onClick={hideItemAdd}>
                    Go Back
                  </Button>
                </div>
                <div className="col-md-6 text-right">
                  <Button variant="primary" type="submit">
                    Add {auth()?.transTask}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
}

export { AddTask };
