import React, { useState, useEffect, useContext } from "react";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Sliders,
  CloudDownload,
  ArrowUp,
  ArrowDown,
  Check,
} from "react-bootstrap-icons";
import { Files } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { useAuthUser } from "react-auth-kit";
import { SlidesDisplaySimple } from "../common/slides-display-simple";
import Spinner from "react-bootstrap/Spinner";
import { useGlobalContext } from "../../GlobalContext";
import PPTXIcon from "../../assets/images/pptx.jpg";
import slides_a from "../../assets/images/slides_a.png";
import { UserContext } from "../../context/UserContext";
import moment from "moment";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

function ListTemplates(props: any) {
  const [showImages, setShowImages] = useState(false);
  const [showTemplateDeleteConfirm, setShowTemplateDeleteConfirm] =
    useState(false);
  const [Template, setTemplate] = useState();
  const [TemplateId, setTemplateId] = useState();
  const [images, setImages] = useState([]);
  const [errorsDelete, setErrorsDelete] = useState([] as Error[]);
  const [isValidDelete, setIsValidDelete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuthUser();
  const context = useGlobalContext();
  const [sortColumn, setSortColumn] = useState<string | null>("name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [showList, setShowList] = useState(0);
  const { user } = useContext(UserContext);
  const [isPhone, setIsPhone] = useState(window.innerWidth < 768);
  const [templatesAvailable, setTemplatesAvailable] = useState(0);
  const [templatesUsed, setTemplatesUsed] = useState(0);
  const [templatesTotal, setTemplatesTotal] = useState(0);
  const navigate = useNavigate();

  const handleCloseImages = () => setShowImages(false);
  const handleCloseTemplateDeleteConfirm = () =>
    setShowTemplateDeleteConfirm(false);

  const handleShowImages = (item: any) => {
    setTemplate(item.name);
    setShowImages(true);
    setImages(item.slides);
  };

  useEffect(() => {
    getBalance();
    const handleResize = () => {
      setIsPhone(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (props.getItemsFlag === false) {
      setShowList(0);
    } else if (props.items != null && props.items.length === 0) {
      setShowList(1);
    } else if (props.items != null && props.items.length > 0) {
      setShowList(2);
    }
  }, [props.items]);

  const getBalance = async () => {
    try {
      const response = await ApiService.getTemplateBalance();
      setTemplatesAvailable(response.data.templatesAvailable);
      setTemplatesUsed(response.data.templates);
      setTemplatesTotal(response.data.templatesTotal);
    } catch (e) {
      console.log("Error");
    }
  };

  const handleShowTemplateDeleteConfirm = (item: any) => {
    setTemplateId(item.id);
    setTemplate(item.name);
    setShowTemplateDeleteConfirm(true);
  };

  const handleDeleteTemplate = (id: any) => {
    ApiService.deleteTemplate(id).then((response: any) => {
      if (response.data.length === 0) {
        props.getItems();
        handleCloseTemplateDeleteConfirm();
      } else {
        setIsValidDelete(false);
        setErrorsDelete(response.data);
      }
    });
  };

  const downloadTemplate = (id: string) => {
    try {
      var apiUrl = context.apiUrl + "api/";
      setIsLoading(true);
      ApiService.getDownloadTemplate(id).then((response: any) => {
        setIsLoading(false);
        window.open(
          apiUrl + "templates/download/direct/" + response.data,
          "_blank"
        );
      });
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
      setIsLoading(false);
    }
  };

  const handleShowTemplateEdit = (item: any) => {
    props.setItemEdit(item);
    props.setSelectedEdit(true);
  };

  const toggleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortItems = (items: any[]) => {
    if (!sortColumn) return items;

    return [...items].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const renderSortArrow = (column: string) => {
    if (sortColumn !== column) {
      return null;
    }

    if (sortDirection === "desc") {
      return <ArrowDown />;
    } else {
      return <ArrowUp />;
    }
  };

  const startCollaboration = async (name: string, templateId: string) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", "");
      formData.append("templateId", templateId);
      const response = await ApiService.addCollaborationFromTemplateQuick(
        formData
      );
      if (response.data.length > 0) {
        setErrorsDelete(response.data);
        navigate("/dashboard?c=" + response.data);
      }
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-background">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {showList === 1 && (
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img
              src={slides_a}
              className="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold lh-1 mb-3">
              Presentation Templates
            </h1>
            <br />
            <p className="lead">
              Choose from custom templates or let our{" "}
              <strong>AI-powered</strong> Smart Templates craft a tailored
              presentation outline for you, based on your specific topic of
              interest.
            </p>
            <br />
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <button
                type="button"
                className="btn btn-primary btn-lg px-4 me-md-2"
                onClick={() => props.setSelectedAddAi(true)}
              >
                Generate Smart Template
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary btn-lg px-4"
                onClick={() => props.setSelectedAdd(true)}
              >
                Upload Your Own
              </button>
            </div>
          </div>
        </div>
      )}
      {showList === 2 && (
        <>
          <div className="row mb-3">
            <div className="col-md-12 text-right">
              {templatesAvailable < 1 && (
                <div className="alert alert-secondary" role="alert">
                  You have used all your templates. Please buy more.
                </div>
              )}
              {templatesAvailable > 0 && (
                <Button
                  variant="primary-outline"
                  onClick={() => props.setSelectedAddAi(true)}
                >
                  Generate Smart Template
                </Button>
              )}
              &nbsp;
              <Button
                variant="primary-outline"
                onClick={() => props.setSelectedAdd(true)}
              >
                Add Template
              </Button>
            </div>
          </div>
          <table className="table fs-0 mb-0 border-top border-200">
            <thead>
              <tr>
                <th
                  className="mouse-pointer"
                  onClick={() => toggleSort("name")}
                >
                  Name&nbsp;{renderSortArrow("name")}
                </th>
                {!isPhone && (
                  <>
                    <th
                      className="text-center"
                      onClick={() => toggleSort("created")}
                    >
                      Created&nbsp;{renderSortArrow("created")}
                    </th>
                  </>
                )}
                <th className="text-center">Slides</th>
                {!isPhone && (
                  <>
                    <th className="text-center">Type</th>
                  </>
                )}
                {user?.istenantadmin && (
                  <>
                    <th
                      className="mouse-pointer"
                      onClick={() => toggleSort("category")}
                    >
                      Category&nbsp;{renderSortArrow("category")}
                    </th>
                    <th
                      className="mouse-pointer"
                      onClick={() => toggleSort("industry")}
                    >
                      Industry&nbsp;{renderSortArrow("industry")}
                    </th>

                    <th className="text-center">Visible</th>
                    <th className="text-center">Theme</th>
                    <th className="text-center">Public</th>
                  </>
                )}

                <th className="text-center">Download</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sortItems(props.items).map((item: any) => (
                <tr key={item.id}>
                  <td>
                    {item.name}
                    {isPhone && (
                      <>
                        <br />
                        <span
                          className="badge rounded-pill bg-secondary"
                          title={moment(item.created).format(
                            "YYYY-MMM-DD HH:mm"
                          )}
                        >
                          {item.createdString}
                        </span>
                      </>
                    )}
                  </td>
                  {!isPhone && (
                    <>
                      <td className="text-center">
                        <span
                          className="badge rounded-pill bg-secondary"
                          title={moment(item.created).format(
                            "YYYY-MMM-DD HH:mm"
                          )}
                        >
                          {item.createdString}
                        </span>
                      </td>
                    </>
                  )}
                  <td className="text-center">
                    <span
                      onClick={() => handleShowImages(item)}
                      className="mouse-pointer"
                    >
                      <Files />
                    </span>
                  </td>
                  {!isPhone && (
                    <>
                      <td className="text-center">
                        <img
                          src={PPTXIcon}
                          alt="PowerPoint"
                          title="PowerPoint"
                          width="25"
                        ></img>
                      </td>
                    </>
                  )}
                  {user?.istenantadmin && (
                    <>
                      <td>{item.category}</td>
                      <td>{item.industry}</td>
                      <td className="text-center">
                        {item.isVisible && (
                          <>
                            <Check />
                          </>
                        )}
                      </td>
                      <td className="text-center">
                        {item.isTheme && (
                          <>
                            <Check />
                          </>
                        )}
                      </td>
                      <td className="text-center">
                        {item.isPublic && (
                          <>
                            <Check />
                          </>
                        )}
                      </td>
                    </>
                  )}
                  <td className="text-center">
                    <span
                      onClick={() => downloadTemplate(item.id)}
                      className="mouse-pointer"
                    >
                      <CloudDownload />
                    </span>
                  </td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id="dropdown-basic"
                        size="sm"
                      >
                        <Sliders size={10} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => startCollaboration(item.name, item.id)}
                        >
                          Start Collaborating
                        </Dropdown.Item>
                        {item.canEdit && (
                          <Dropdown.Item
                            onClick={() => handleShowTemplateEdit(item)}
                            disabled={!item.canEdit}
                          >
                            Edit
                          </Dropdown.Item>
                        )}
                        {item.canDelete && (
                          <Dropdown.Item
                            disabled={!item.canDelete}
                            onClick={() =>
                              handleShowTemplateDeleteConfirm(item)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      <Modal
        show={showImages}
        onHide={handleCloseImages}
        backdrop="static"
        keyboard={false}
        className="modal-xl"
      >
        <Modal.Header>
          <Modal.Title>Template - {Template}</Modal.Title>
          <Button variant="close" onClick={handleCloseImages}></Button>
        </Modal.Header>
        <Modal.Body>
          <SlidesDisplaySimple slides={images} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary-outline" onClick={handleCloseImages}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showTemplateDeleteConfirm}
        onHide={handleCloseTemplateDeleteConfirm}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Template}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isValidDelete &&
            errorsDelete.map((error: Error, index) => (
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
                key={index}
              >
                {error.message}
              </div>
            ))}
          Are you sure you want to delete this template?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary-outline"
            onClick={handleCloseTemplateDeleteConfirm}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteTemplate(TemplateId)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { ListTemplates };
